/**
 * dashboard.jsx: Acts as a user dashboard with updates, and notifications.
 *                Default menu for the profile page
 * Author: Benni Delgado and CJ Larsen
 * Arizona Institute for Resilience
 */
import React from 'react';
import { Container, Row, Col, Form, Table, Spinner } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { RainChart } from './visualizations';

const CITIES_MAP = {
    tucson: "Tucson",
    phoenix: "Phoenix",
    flagstaff: "Flagstaff",
    el_paso: "El Paso",
    albuquerque: "Albuquerque"
};
const ESTIMATE_MONTHS = ["July", "August", "September"];

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        const today = new Date();

        this.state = {
            currCity: "Tucson",
            currMonth: this.props.info.currMonth,
            currYear: String(today.getUTCFullYear()),
            rain: {},
            data: {},
            loading: true,
            rainChartMonth: this.getRainChartMonth(this.props.info.currMonth)
        };

        document.querySelector("body").scrollTo(0, 0);
    }

    /**
     * Returns the month that the rainfall line graph should display.
     *
     * @param {string} currMonth - The current forecasting month. This
     *    is usually the next month e.g. in July this will be "August".
     */
    getRainChartMonth(currMonth) {
        // Get the index of the actual month for today. Won't be valid
        // outside July/August/Sept
        const actualMonthIndex = new Date().getMonth() - 6;

        const monthIndex = ESTIMATE_MONTHS.indexOf(currMonth);
        if (monthIndex === 0) {
            // if forecasting month is July go ahead and show July,
            // even though it'll be empty.
            return ESTIMATE_MONTHS[monthIndex];
        } else if (actualMonthIndex === 2) {
            return ESTIMATE_MONTHS[actualMonthIndex]
        } else if (monthIndex < 0) {
            // If the month isn't in estimate_months, just show the last
            // forecasting month.
            return ESTIMATE_MONTHS[ESTIMATE_MONTHS.length - 1 ];
        } else {
            // Otherwise go back one month from the forecasting month
            // to show the current rainfall
            return ESTIMATE_MONTHS[monthIndex - 1];
        }
    }

    /**
     * Checks on mount to get the user's estimates (if they exist) and also the
     * live rain data.
     */
    async componentDidMount() {
        const { currYear } = this.state;
        //get firestore objects
        const { firestore } = this.props;
        const { uid } = this.props.user;

        //connect to db with new structure
        const surveysRef = firestore.collection('surveys').doc(currYear).collection('startSeason');
        const liveRainRef = firestore.collection('liveRain');
        const estimatesRef = firestore.collection('season').doc(currYear).collection('estimates');

        try {
            // check if user has survey for this year
            const userDoc = await surveysRef.doc(uid).get();
            if (!userDoc.exists || userDoc.data().wholeSeasonForecast === "") {
                // doesn't exist -> it's their first login -> send to profile page
                //this.props.changeMenu({ key: 'profile', name: 'Profile' });
                this.props.history.push('/profile')
            } else {
                const userData = userDoc.data();
                const username = userData.username;
                // get live rain data
                const liveRainId = currYear > 2021 ? currYear : 'rain';
                const liveRain = await liveRainRef.doc(liveRainId).get();
                // user does exist, check if they have doc in season/{year}/estimates collection
                const estimateUserDoc = await estimatesRef.doc(uid).get();

                if (!estimateUserDoc.exists) {
                    const defaultDoc = {
                        uid: uid,
                        username: username,
                        year_points: 0,
                        july: { month_points: 0 },
                        august: { month_points: 0 },
                        september: { month_points: 0 }
                    };

                    // doesn't exist, create default doc for them
                    try {
                        await estimatesRef.doc(uid).set(defaultDoc);
                    } catch (e) {
                        this.props.toast('Error Establishing User Account', 'error');
                        console.error(e);
                    }

                    this.setState({ rain: liveRain.data(), data: defaultDoc, loading: false });
                } else {
                    this.setState({ rain: liveRain.data(), data: estimateUserDoc.data(), loading: false });
                }

            }
        } catch (e) {
            console.error(e);
        }

    }

    render() {
        const { currCity, currMonth, rain, data, loading, rainChartMonth } = this.state;
        const { changeMenu, setCity, info, history } = this.props;
        let estimateMonth = info.currMonth;

        if (!ESTIMATE_MONTHS.includes(estimateMonth)) {
            estimateMonth = "September";
        }

        // hides estimate cards if we're not taking any more guesses
        var hideEstimateCards = false;
        if(!ESTIMATE_MONTHS.includes(currMonth)){
            hideEstimateCards = true;
        }

        const chartWidth = window.innerWidth < 768 ? window.innerWidth - 75 : 800;
        const chartHeight = chartWidth / 1.6;

        return (
            <motion.div animate={{ opacity: [0, 1] }}>
                <Container fluid>
                    {rain[rainChartMonth] === undefined ? null : // maybe loading thingy?
                        <>
                            <Row className="mt-5">
                                <Col>
                                    <h3 className="justify-content-center" style={{ padding: 15, fontSize: '2em' }} > Rainfall for {rainChartMonth}</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <Form.Group className="mt-2">
                                        <Form.Control
                                            as="select"
                                            onChange={e => this.setState({
                                                currCity: e.target.value
                                            })}
                                        >
                                            <option>Tucson</option>
                                            <option>Phoenix</option>
                                            <option>Flagstaff</option>
                                            <option>El Paso</option>
                                            <option>Albuquerque</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex mb-4 justify-content-center">
                                    <div id='liveRainChart' className="card-small">
                                        <RainChart
                                            width={chartWidth} // 800 - 1.6 ratio
                                            height={chartHeight} // 500
                                            city={currCity}
                                            month={rainChartMonth.charAt(0).toUpperCase() + rainChartMonth.slice(1)}
                                            data={rain[rainChartMonth][currCity]}
                                            margin={25} />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                    { hideEstimateCards ? null :
                    <>
                    <Row className="justify-content-center" style={{ padding: 15 }}>
                        <h3 style={{fontSize: '2em'}}>Your Submitted Forecasts for {currMonth}</h3>
                    </Row>
                    <Row xs={1} md={3} lg={5} className="justify-content-center">
                        <Col className="d-flex justify-content-center mb-4">
                            <EstimateCard
                                city='tucson'
                                data={data}
                                month={currMonth.toLowerCase()}
                                estimateMonth={estimateMonth}
                                rain={rain}
                                changeMenu={changeMenu}
                                setCity={setCity}
                                loading={loading}
                                history={history}
                            />
                        </Col>
                        <Col className="d-flex justify-content-center mb-4">
                            <EstimateCard
                                city='phoenix'
                                data={data}
                                month={currMonth.toLowerCase()}
                                estimateMonth={estimateMonth}
                                rain={rain}
                                changeMenu={changeMenu}
                                setCity={setCity}
                                loading={loading}
                                history={history}
                            />
                        </Col>
                        <Col className="d-flex justify-content-center mb-4">
                            <EstimateCard
                                city='flagstaff'
                                data={data}
                                month={currMonth.toLowerCase()}
                                estimateMonth={estimateMonth}
                                rain={rain}
                                changeMenu={changeMenu}
                                setCity={setCity}
                                loading={loading}
                                history={history}
                            />
                        </Col>
                        <Col className="d-flex justify-content-center mb-4">
                            <EstimateCard
                                city='el_paso'
                                data={data}
                                month={currMonth.toLowerCase()}
                                estimateMonth={estimateMonth}
                                rain={rain}
                                changeMenu={changeMenu}
                                setCity={setCity}
                                loading={loading}
                                history={history}
                            />
                        </Col>
                        <Col className="d-flex justify-content-center mb-4">
                            <EstimateCard
                                city='albuquerque'
                                data={data}
                                month={currMonth.toLowerCase()}
                                estimateMonth={estimateMonth}
                                rain={rain}
                                changeMenu={changeMenu}
                                setCity={setCity}
                                loading={loading}
                                history={history}
                            />
                        </Col>
                    </Row>
                    </>
                    }
                    <Row className="mt-5">
                        <Col>
                            <h3>Quick Look</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                    <Row>
                        <ScoreTable month="July" currMonth={currMonth} data={data.july} rain={rain} />
                        <ScoreTable month="August" currMonth={currMonth} data={data.august} rain={rain} />
                        <ScoreTable month="September" currMonth={currMonth} data={data.september} rain={rain} />
                    </Row>
                </Container>
            </motion.div >
        )
    }
}

function getMonthFromString(mon) {
    return new Date(Date.parse(mon + " 1, 2012")).getMonth() + 1;
}

function EstimateCard(props) {
    const { city, data, month, estimateMonth, rain, changeMenu, setCity, loading, history } = props;

    if (loading) {
        // loading, give em the spinny
        return (
            <motion.div
                className="card-small"
                whileHover={{ scale: 1.1 }}
                animate={{ opacity: [0, 1] }}
                onClick={() => {
                    setCity(CITIES_MAP[city]);
                    //changeMenu({ key: 'estimate', name: `Make ${estimateMonth} Forecasts` });
                    history.push('/estimate/' + city);
                }}>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Spinner className="d-flex justify-content-center" animation="border" variant="success" />
                    </Col>
                </Row>
            </motion.div>
        )
    } else if (data === null || rain === {} || data[month] === undefined || data[month][city] === undefined) {
        // no estimate/data, render default card
        return (
            <motion.div
                className="card-small"
                whileHover={{ scale: 1.1 }}
                animate={{ opacity: [0, 1] }}
                onClick={() => {
                    setCity(CITIES_MAP[city]);
                    //changeMenu({ key: 'estimate', name: `Make ${estimateMonth} Forecasts` });
                    history.push('/estimate/' + city)
                }}>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <h4><u>{CITIES_MAP[city]}</u></h4>
                    </Col>
                </Row>
                <Row className="d-flex mt-5 align-content-center">
                    <Col className="d-flex justify-content-center">
                        <h3 style={{ color: '#f0534d' }}>No Forecast</h3>
                    </Col>
                </Row>
                <Row className="d-flex mt-5 align-content-center">
                    <Col className="d-flex justify-content-center" >
                        <h4>Click to make Forecast!</h4>
                    </Col>
                </Row>
            </motion.div>
        )
    } else {
        return (
            <motion.div
                className="card-small"
                whileHover={{ scale: 1.1 }}
                animate={{ opacity: [0, 1] }}
                onClick={() => {
                    setCity(CITIES_MAP[city]);
                    //changeMenu({ key: 'estimate', name: `Make ${estimateMonth} Forecasts` });
                    history.push('/estimate/' + city);
                }}>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <h4><u>{CITIES_MAP[city]}</u></h4>
                    </Col>
                </Row>
                <Row xs={1}>
                    <Col className="d-flex justify-content-center">
                        <h3 style={{ color: "#3cbc74" }}>{data[month][city].estimate} in.</h3>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <h4>My Forecast</h4>
                    </Col>
                </Row>
                <Row xs={1}>
                    <Col className="d-flex justify-content-center">
                        <h3 style={{ color: "#3cbc74" }}>{data[month][city].points.toFixed(2)}</h3>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <h4>Current Points</h4>
                    </Col>
                </Row>
            </motion.div>
        )
    }
}

function ScoreTable(props) {
    const { currMonth, month, data, rain } = props;

    const activeMonth = getMonthFromString(currMonth) >= getMonthFromString(month) ? true : false;

    // check if required data is loaded yet
    if (data === undefined || rain === undefined) {
        return (
            <Col className="d-flex justify-content-center">
                <Spinner className="d-flex justify-content-center" animation="border" variant="success" />
            </Col>)
    } else if (!rain[month]){
        return (
            <Col className="d-flex justify-content-center col-12 col-md-4">
                <h4 className="btn btn-secondary btn-block">No Current Rain Data For {month}</h4>
            </Col>)
    }

    else {
        return (
            <Col className="d-flex mb-4 justify-content-center">
                <div>
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>{month}</th>
                                <th>Forecast</th>
                                <th>Actual Rainfall</th>
                                <th>Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(CITIES_MAP).map((city) => {
                                return (
                                    <tr key={`${city}-${month}`}>
                                        <td>{CITIES_MAP[city]}</td>
                                        {data[city] === undefined ?
                                            <td colSpan="3">No Forecast</td> :
                                            <>
                                                <td>{data[city].estimate}"</td>
                                                <td>{activeMonth ? (Math.round(rain[month][CITIES_MAP[city]].reduce((a, b) => a + b, 0) * 100) / 100).toString() + "\"" : "0\""}</td>
                                                <td>{activeMonth ? data[city].points.toFixed(2) : 0}</td>
                                            </>}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </Col>
        )
    }
}

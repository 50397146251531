/**
 * terms.jsx: simple wrapper for the terms of use to make it
 * publicly viewable
 * Author: Thomas Weiss
 * Arizona Institute for Resilience
 */

import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/footer';
import { motion } from 'framer-motion';

import Terms from "../components/terms";

export default function Home(props) {
    const user = props.user;
    return (
        <>
            <main>
                <NavBar user={user} signIn={props.signIn} history={props.history} />
                <div style={{ marginTop: '7em' }}>
                    <motion.div animate={{ opacity: [0, 1] }} className="card-large p-4">
                        <h1 className="h2 text-center mb-4">Terms and Conditions for Monsoon Fantasy Forecasts</h1>
                        <Terms />
                    </motion.div>
                </div>
            </main>
            <Footer />
        </>
    )
}



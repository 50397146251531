/**
 * Author: Roger Palmenberg and Thomas Weiss
 * Arizona Institute for Resilience 
 * outlooks.jsx is a useful page for users to get information about forecast 
 * outlooks and how they can help with Monsoon Fantasy forecasts. 
 */

import React from 'react';
import { motion } from 'framer-motion';
import { Row, Container, Col, Table } from 'react-bootstrap';
import { Collapse } from './wrappers';

// city map to match format of outlooks collection on firestore
const CITY_MAP = {
    'FLAGSTAFF AP': 'Flagstaff',
    'ALBUQUERQUE INTL AP': 'Albuquerque',
    'TUCSON INTERNATIONAL AIRPORT': 'Tucson',
    'EL PASO INTL AP': 'El Paso',
    'PHOENIX AIRPORT': 'Phoenix'
}

export default class Outlooks extends React.Component {

    constructor(props) {
        super(props);
        // null to make sure we pulled the data successfully down below
        this.state = {
            stations: null, 
            outlooks: null,
        }
    }

    componentDidMount(){
        this.getData();
    }

    getData = async () => {
        // query to grab all data for most recent outlooks
        this.props.firestore.collection('outlooks').orderBy('created', 'desc').limit(1).get().then((async (query) => {
            query.forEach((doc) => {
                const data = doc.data();
                var stations = [];
                for(let i = 0; i < Object.keys(data['outlooks']).length; i++){
                    // loop for each station that populates data
                    stations.push(Object.keys(data['outlooks'])[i]);
                }
                // sorts alphabetically
                stations.sort();
                this.setState({
                    stations: stations, 
                    outlooks: data['outlooks']
                });
            })
        }));
    }

    render(){
        return(
            <motion.div className='card-large'>
                <Container fluid>
                    <Col>
                        <Row className="d-flex align-content-left pt-3 pb-2">
                            <h2 className='h4'>Outlook Dropdowns</h2>
                        </Row>
                    </Col>
                    <Col>
                        { this.state.outlooks === null ? null : 
                            this.state.stations.map((station) => (
                                    <Collapse title={`${CITY_MAP[station]} (${station})`} key={station}>
                                            <Table responsive striped bordered hover variant='dark'>
                                                <thead>
                                                    <tr>
                                                        <th>Outlook Type</th>
                                                        <th>Forecast Period</th>
                                                        <th>Rain Forecast</th>
                                                        <th>Probability</th>
                                                        <th>Forecast Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.outlooks[station].map((item) => (
                                                    <tr key={item['Station'] + '-' + item['Product']}>
                                                        <td>{item['Product']}</td>
                                                        <td>{item['Forecast Period']}</td>
                                                        <td>{item['Forecast']}</td>
                                                        <td>{item['Probability']}</td>
                                                        <td>{item['Forecast Date']}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                    </Collapse>
                                ))
                        }
                    </Col>
                    <Col>
                        <Row className="d-flex align-content-left pt-2">
                            <h2 className='h4'>What Are Outlooks and How Are They Useful in Forecasting?</h2>
                        </Row>
                        <Row className="d-flex align-content-left pt-2">
                            <p>
                                To aid in your Monsoon Fantasy predictions, we present several precipitation 
                                outlooks at different timescales for the five cities in the competition. These 
                                forecasts are different from the typical ones you access on your phone or see on 
                                TV that represent the chance of rain or a forecasted amount. They are probabilistic
                                forecasts, meaning that probabilities are given to precipitation falling into 
                                different ranges.
                            </p>
                        </Row>
                        <Row className="d-flex align-content-left pt-2">
                            <p>
                                These outlooks are issued by the NOAA-NWS Climate Prediction Center. They indicate 
                                the probability that the precipitation amount over the forecast period falls into one
                                of three categories: "above-average", "normal", or "drier-than-average". For the 3-4 
                                Week outlook, the precipitation is derived into one of two categories (either below or 
                                above average). These categories are derived by looking at the historical distribution of 
                                precipitation at a location over the same forecast period and simply splitting that 
                                distribution into thirds, or terciles. The outlook, then, indicates the probability that 
                                the total precipitation over the forecast period will fall into each of the three 
                                categories. The probability across all three categories must equal 100%. A 33-40% 
                                probability of “drier-than-average” precipitation indicates a slight lean towards drier 
                                conditions over the forecast period. That leaves 33% chance of “normal” and 27-33% chance 
                                of “above-average” precipitation amounts. An “equal-chances” outlook is just what it 
                                sounds like, an equal chance (33%) that precipitation could fall into any of the three 
                                categories. We present here the most likely category based on the outlook at each city 
                                for the different forecast periods.
                            </p>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="d-flex align-content-left pt-3">
                            <h2 className='h4'>Additional Resources</h2>
                        </Row>
                        <Row className="d-flex align-content-left pt-2">
                            <Table striped bordered hover responsive variant="dark">
                                <thead>
                                    <tr>
                                        <th>Outlook</th>
                                        <th>Update Frequency</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href = {`https://www.cpc.ncep.noaa.gov/products/predictions/610day/`} target = '_blank'>
                                            6-10 Day Outlook Link
                                            </a>
                                        </td>
                                        <td>Daily</td>
                                        <td>3-Category (above/normal/below)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href = {`https://www.cpc.ncep.noaa.gov/products/predictions/814day/`} target = '_blank'>
                                            8-14 Day Outlook Link
                                            </a>
                                        </td>
                                        <td>Daily</td>
                                        <td>3-Category (above/normal/below)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href = {`https://www.cpc.ncep.noaa.gov/products/predictions/WK34/`} target = '_blank'>
                                            3-4 Week Outlook Link
                                            </a>
                                        </td>
                                        <td>Every Friday</td>
                                        <td>2-Category (above/below)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href = {`https://www.cpc.ncep.noaa.gov/products/predictions/long_range/lead14/`} target = '_blank'>
                                            1 Month Outlook Link
                                            </a>
                                        </td>
                                        <td>Twice a Month (3rd Thursday and last day of the month)</td>
                                        <td>3-Category (above/normal/below)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href = {`https://www.cpc.ncep.noaa.gov/products/predictions/long_range/`} target = '_blank'>
                                            3 Month Outlook Link
                                            </a>
                                        </td>
                                        <td>3rd Thursday of the Month</td>
                                        <td>3-Category (above/normal/below)</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                </Container>
            </motion.div>
        )
    }
}
/**
 * groupleaderboards.jsx: Simple leaderboard for use with groups.
 * Author: Thomas Weiss
 * Arizona Institute for Resilience
 */

import React from 'react';
import firebase from 'firebase/app';
import { Container, Row, Col, Form, Table, Spinner } from 'react-bootstrap';

const INVERTED_CITIES_MAP = {
    "Tucson": 'tucson',
    "Phoenix": 'phoenix',
    "Flagstaff": 'flagstaff',
    "El Paso": 'el_paso',
    "Albuquerque": 'albuquerque'
};

const MONTH_KEYS = ['july', 'august', 'september'];
const CITY_KEYS = ['tucson', 'phoenix', 'flagstaff', 'el_paso', 'albuquerque'];

export default class GroupLeaderboards extends React.Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const currYear = String(today.getUTCFullYear());
        const currMonth = today.getMonth() + 1; // +1 because it is 0 based
        const selectedYear = currMonth >= 7 ? currYear : String(currYear - 1);
        // if month is before july, displays results from previous year. otherwise, displays current year.

        var yearList = [];
        for(let i = Number(selectedYear); i >= 2021; i--){
            //making a list of years going back to 2021 (first year of game)
            //we're starting at selectedYear, meaning we'll skip the current
            //year if still pre-season
            yearList.push(String(i));
        }

        this.state = {
            currMonth: currMonth,
            currYear: currYear,
            selectedYear: props.selectedYear ?? selectedYear,
            selectedMonth: props.selectedMonth ?? null,
            selectedCity: props.selectedCity ?? null,
            selectableMonths: props.selectableMonths ?? this.determineMonths(currYear, selectedYear, currMonth),
            selectableYears: yearList,
            showForm: props.showForm ?? true,
            showEstimate: false,
            hideYear: props.hideYear ?? false,
            memberList: props.memberList,
            secondaryMemberList: props.secondaryMemberList ?? []
        }
    }


    componentDidMount() {
        this.fetchData(this.state.selectedYear, this.state.memberList);
        if(this.props.secondaryMemberList){
            this.fetchData(this.state.selectedYear, this.state.secondaryMemberList);
        }
    }

    getTotal(leaderboard){
        var retVal = 0;
        for(let user of leaderboard){
            if(!user.disabled){
                retVal += user.value;
            }
        }
        return retVal;
    }

    getAverage(leaderboard){
        var retVal = 0;
        var users = 0;
        for(let user of leaderboard){
                retVal += user.value;
                users++;
        }
        return isNaN(retVal / users) ? 0 : retVal / users;
    }

    getGroupOverviews(){
        var retVal = [];
        retVal.push(
            {
                name: this.props.primaryGroupName,
                total: this.getTotal(this.state['leaderboardData-displayed'] ?? []),
                average: this.getAverage(this.state['leaderboardData-displayed'] ?? [])
            }
        )
        if(this.props.secondaryMemberList){
            retVal.push(
                {
                    name: this.props.secondaryGroupName,
                    total: this.getTotal(this.state['secondaryLeaderboardData-displayed'] ?? []),
                    average: this.getAverage(this.state['secondaryLeaderboardData-displayed'] ?? [])
                }
            )
        }
        return retVal;
    }

    async fetchData(year, memberList) {
        const { firestore } = this.props;
        var leaderboardName = 'leaderboardData' + String(year);
        if(this.props.memberList != memberList){
            leaderboardName = 'secondaryLeaderboardData' + String(year);
        }
        if(this.state[leaderboardName] === undefined){
            var userlist = [];
            for(let i = 0; i < memberList.length; i++){
                userlist.push(memberList[i].uid);
            }
            const seasonRef = firestore.collection('season').doc(year).collection('estimates');
            var leaderboardData = [];
            if(userlist.length > 0){
                // list comparison is limited to 10 entries (thanks, firestore)
                // need to potentially run the where() function multiple times
                // depending on group size
                var runs = 1;
                var firestore_limit = 10; //in case this ever increases
                if(userlist.length > firestore_limit){
                    runs = Math.floor(userlist.length / firestore_limit); // e.g., if 36, this should return 3
                    if(userlist.length % firestore_limit > 0){
                        runs++; // add an extra run if there's a remainder
                    }
                }
                for(let i = 0; i < runs; i++){
                    //slice the input array based on which run we're on
                    await seasonRef.where(firebase.firestore.FieldPath.documentId(), 'in', userlist.slice(i * firestore_limit, (i+1) * firestore_limit)).get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            leaderboardData.push(doc.data())
                        })
                    }).catch((e) => {
                        console.error(e);
                    });
                }
                //get cumulative city scores
                for(let user of leaderboardData){
                    //for each user
                    var cumulative = {};
                    for(let city of CITY_KEYS){
                        //for each city
                        var city_cumulative = 0;
                        for(let month of MONTH_KEYS){
                            //for each month
                            city_cumulative = city_cumulative + (user[month][city] === undefined ? 0 : user[month][city].points);
                        }
                        cumulative[city] = city_cumulative;
                    }
                    user['city_cumulative_scores'] = cumulative;
                }
                var nonPlayers = [];
                for(let user of memberList){
                    var inLeaderboard = false;
                    for(let leaderboard_user of leaderboardData){
                        if(leaderboard_user.uid == user.uid){
                            inLeaderboard = true;
                        }
                    }
                    if(!inLeaderboard){
                        nonPlayers.push({
                            uid: user.uid,
                            username: user.username,
                        })
                    }
                }
                this.setState({ [leaderboardName]: leaderboardData });
                this.setState({[leaderboardName + '-nonplayers']: nonPlayers});
            } 
        }
        this.props.sendPrimaryGroupData(this.state['leaderboardData' + String(year)]);
        this.sortLeaderboard(this.state.selectedCity, this.state.selectedMonth, year);
    }

    sortLeaderboard(city, month, year) {
        // uses .sort() to sort the list of leaderboard entries
        // see documentation for more information: 
        //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
        month = month == 'total' ? null : month;
        city = city == 'total' ? null : city;
        year = year == null ? this.state.currYear : year; //somehow year is null, let's default to currYear in that case

        //by default we don't want to show the estimate
        //unless we are sorting by city-month
        this.setState({ showEstimate: false });

        //we want to sort both leaderboard (if they exist)
        var userLimit = 0;
        var leaderboardList = ['leaderboardData'];
        if(this.state[leaderboardList[0] + year]){
            userLimit = this.state[leaderboardList[0] + year].length;
        }
        
        if(this.props.secondaryMemberList && this.state['secondaryLeaderboardData' + year]){
            leaderboardList.push('secondaryLeaderboardData')
            //we have a second list, we should check the lengths and get the smaller limit
            var primaryLength = this.state[leaderboardList[0] + year].length;
            var secondaryLength = this.state[leaderboardList[1] + year].length;
            if(primaryLength >= secondaryLength){
                userLimit = secondaryLength;
            } else {
                userLimit = primaryLength;
            }
        }

        for(let leaderboard of leaderboardList){
            if(this.state[leaderboard + year]){
                var sortedData = [];
                var returnList = [];
                if(city === null){
                    //no city, use overall points
                    if(month === null){
                        //no month, sort by year_points
                        sortedData = this.state[leaderboard + year].sort((a, b) => b.year_points - a.year_points);
                        for(let i = 0; i < sortedData.length; i++){
                            returnList.push({
                                username: sortedData[i].username,
                                uid: sortedData[i].uid,
                                rank: i + 1,
                                value: sortedData[i].year_points,
                                disabled: i + 1 > userLimit ? true : false
                            })
                        }
                    } else {
                        //month, sort by month_points
                        sortedData = this.state[leaderboard + year].sort((a, b) => b[month].month_points - a[month].month_points);
                        for(let i = 0; i < sortedData.length; i++){
                            returnList.push({
                                username: sortedData[i].username,
                                uid: sortedData[i].uid,
                                rank: i + 1,
                                value: sortedData[i][month].month_points,
                                disabled: i + 1 > userLimit ? true : false
                            })
                        }
                    }
                } else {
                    //city, determine city points
                    if(month === null){
                        //city, overall
                        sortedData = this.state[leaderboard + year]
                            .sort((a, b) => b.city_cumulative_scores[city] - a.city_cumulative_scores[city]);
                        for(let i = 0; i < sortedData.length; i++){
                            returnList.push({
                                username: sortedData[i].username,
                                uid: sortedData[i].uid,
                                rank: i + 1,
                                value: sortedData[i].city_cumulative_scores[city],
                                disabled: i + 1 > userLimit ? true : false
                            });
                        }
                    } else {
                        //city, sorted by month
                        sortedData = this.state[leaderboard + year]
                            .sort(
                                (a, b) => {
                                    return (b[month][city] === undefined ? -1 : b[month][city].points)
                                    - (a[month][city] === undefined ? -1 : a[month][city].points)
                                });
                        for(let i = 0; i < sortedData.length; i++){
                            returnList.push({
                                username: sortedData[i].username,
                                uid: sortedData[i].uid,
                                rank: i + 1,
                                value: sortedData[i][month][city] === undefined ? 0 : sortedData[i][month][city].points,
                                estimate: sortedData[i][month][city] === undefined ? 'N/A' : sortedData[i][month][city].estimate,
                                disabled: i + 1 > userLimit ? true : false
                            });
                        }
                        this.setState({ showEstimate: true });
                    }
                }
                this.setState({ [leaderboard + '-displayed']: returnList });
            }
        }
        this.setState({selectableMonths: this.determineMonths(this.state.currYear, this.state.selectedYear, this.state.currMonth)})
    }

    determineMonths(currYear, selectedYear, currMonth) {
        if (currYear === selectedYear) {
            // current season
            if (currMonth < 7) { // currMonth is before July
                return [];
            } else if (currMonth === 7) { // currMonth is July
                return ['july'];
            } else if (currMonth === 8) { // currMonth is August
                return ['july', 'august'];
            }
        }
        // previous season or all season predictions made, display all months
        return ['july', 'august', 'september'];
    }


    render() {
        return(
            <>
            { this.state.showForm ? (
            <>
            <Container>
                <Row>
                    <Col className="col-12 col-md-4 ml-auto">
                        <Form.Group>
                            <Form.Label htmlFor="month-select" className="text-white h6">
                                Month
                            </Form.Label>
                            <Form.Control 
                                as="select" 
                                onChange={e => { 
                                    this.setState({selectedMonth: e.target.value});
                                    this.sortLeaderboard(this.state.selectedCity, e.target.value, this.state.selectedYear);
                                }} 
                                className="text-capitalize" 
                                id="month-select"
                                defaultValue={this.state.selectedMonth != null ? this.state.selectedMonth : 'total'}
                            >
                                <option value="total">total</option>
                                {this.state.selectableMonths.map((month) => {
                                    return (
                                        <option value={month} key={month}>
                                            {month}
                                        </option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col className="col-12 col-md-4 mr-auto">
                        <Form.Group>
                            <Form.Label htmlFor="city-select" className="text-white h6">
                                City
                            </Form.Label>
                            <Form.Control 
                                as="select" 
                                onChange={e => {
                                    this.setState({selectedCity: e.target.value});
                                    this.sortLeaderboard(e.target.value, this.state.selectedMonth, this.state.selectedYear);
                                }} 
                                id="city-select"
                                defaultValue={this.state.selectedCity != null ? this.state.selectedCity : 'total'}
                            >
                                <option value="total">
                                    All Cities
                                </option>
                                {Object.keys(INVERTED_CITIES_MAP).map((cityKey) => {
                                    return (
                                        <option value={INVERTED_CITIES_MAP[cityKey]} key={cityKey}>
                                            {cityKey}
                                        </option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    { this.state.hideYear ? null : 
                    (<Col className="col-12 col-md-4">
                        <Form.Group>
                            <Form.Label htmlFor="year-select" className="text-white h6">
                                Year
                            </Form.Label>
                            <Form.Control 
                                as="select" 
                                onChange={e => { 
                                    this.setState({selectedYear: e.target.value});
                                    this.fetchData(e.target.value, this.props.memberList);
                                    if(this.props.secondaryMemberList){
                                        this.fetchData(e.target.value, this.props.secondaryMemberList);
                                    }
                                }} 
                                className="text-capitalize" 
                                id="year-select"
                                defaultValue={this.state.selectedYear}
                            >
                                {this.state.selectableYears.map((year) => {
                                    return (
                                        <option value={year} key={year}>
                                            {year}
                                        </option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>) }
                </Row>
                <Row>
                    <Col>
                    <hr className="border-secondary mt-0" />
                    </Col>
                </Row>
            </Container>
            </>
            ) : null }
            <Container>
                <Row>
                    <Col>
                        <LeaderboardOverview
                            groups={this.getGroupOverviews()}
                        />
                        
                        { this.props.secondaryMemberList ? 
                            <>
                                <p className="small d-block text-center">Average score is calculated using all of the player scores for each group, while the total score removes the lowest scoring members until the group sizes match.</p>
                            </>
                            
                        : null }
                        <hr className="border-secondary mt-0" />
                    </Col>
                </Row>
                <Row>
                    <Col className={this.props.secondaryMemberList ? "col-12 col-xl-6" : "col-12"}>
                        { this.props.secondaryMemberList ? 
                            (<h2 className="h3 group-link" onClick={() => this.props.history.push(`/groups/${this.props.primaryGroupId}/leaderboard`)}>{this.props.primaryGroupName}</h2>)
                            : null }
                        <LeaderboardList
                            leaderboard={this.state['leaderboardData-displayed'] ?? []}
                            nonPlayers={this.state['leaderboardData' + this.state.selectedYear + '-nonplayers'] ?? []}
                            uid={this.props.uid}
                            showEstimate={this.state.showEstimate}
                            average={this.state.primaryAverage}
                            total={this.state.primaryTotal}
                        />
                    </Col>
                    <Col className="col-12 col-xl-6">
                    { this.props.secondaryMemberList ? 
                        (
                        <>
                        <h2 className="h3 group-link" onClick={() => this.props.history.push(`/groups/${this.props.secondaryGroupId}/leaderboard`)}>{this.props.secondaryGroupName}</h2>
                        <LeaderboardList
                            leaderboard={this.state['secondaryLeaderboardData-displayed'] ?? []}
                            nonPlayers={this.state['secondaryLeaderboardData' + this.state.selectedYear + '-nonplayers'] ?? []}
                            uid={this.props.uid}
                            showEstimate={this.state.showEstimate}
                            average={this.state.secondaryAverage}
                            total={this.state.secondaryTotal}
                        />
                        </>) : null }
                    </Col>
                </Row>
            </Container>
            </>
        )
    }
}

function LeaderboardList(props){
    const { leaderboard, nonPlayers, uid, showEstimate } = props;
    var { total, average } = props;
    var sortedNonPlayers = [];
    if(nonPlayers && nonPlayers.length > 0){
        sortedNonPlayers = nonPlayers.sort((a, b) => {
            return a.username.localeCompare(b.username)
        });
    }


    if(leaderboard.length == 0 && sortedNonPlayers.length == 0){
        return(<>
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Spinner className="d-flex justify-content-center" animation="border" variant="light" />
                    </Col>
                </Row>
            </Container>
            </>)
    } else {
        return(
            <>
            <Table responsive striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Username</th>
                        {showEstimate ? <th>Estimate</th> : null}
                        <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                {leaderboard.map((user) => 
                    (<tr style={{ color: user.uid === uid ? "#3cbc74" : null }} key={user.rank} className={user.disabled ? "text-muted" : null}>
                            <td>{user.rank}</td>
                            <td>{user.username} {user.disabled ? '(excluded)' : null }</td>
                            { showEstimate ? <td>{user.estimate}</td> : null }
                            <td>{user.value.toFixed(2)}</td>
                    </tr>)
                )}
                {sortedNonPlayers.map((user) =>
                    (<tr style={{ color: user.uid === uid ? "#3cbc74" : null }} className="text-muted" key={user.username}>
                        <td>N/A</td>
                        <td>{user.username}</td>
                        <td colSpan={showEstimate ? 2 : 1}>Did not play</td>
                    </tr>)
                )}
                </tbody>
            </Table>
            </>
        )
    }
}

function LeaderboardOverview(props){
    const { groups } = props;
    return (
        <>
        <Table responsive striped bordered hover variant="dark">
            <thead>
                <tr>
                    <th>Group Name</th>
                    <th>Average Score</th>
                    <th>Total Score</th>
                </tr>
            </thead>
            <tbody>
                {groups.map((group) => (
                    <tr key={group.name}>
                        <td>{group.name}</td>
                        <td>{group.average.toFixed(2)}</td>
                        <td>{group.total.toFixed(2)}</td>
                    </tr>
                ))
                }
            </tbody>
        </Table>
        </>
    )
}
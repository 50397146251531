/**
 * surveyQuestions.jsx: represents the profile menu for the user's survey data.
 *                      will authenticate with firebase auth, and store any
 *                      changes into firestore.
 * Author: Benni Delgado & CJ Larsen
 * Arizona Institute for Resilience
 */
import React from 'react';
import { motion } from 'framer-motion';
import { Row, Container, Col } from 'react-bootstrap';

export default class Survey extends React.Component {

    render() {
        return (
            <motion.div animate={{ opacity: [0, 1] }} className="card-large">
                <Container fluid>
                    <Row className="d-flex align-content-center" style={{ padding: "1%" }}>
                        <Col className="d-flex justify-content-center">
                            <center>
                                <h4>In the future, we’ll ask why you made specific forecasts and your perceptions of the monsoon season.</h4>
                            </center>
                        </Col>
                    </Row>
                </Container>
            </motion.div>
        )
    }
}
/**
 * leaderboards.jsx: Overall Leaderboard for the monsoon season. Ranked by total
 *                   points, sorted via firestore database.
 * Author: Benni Delgado & CJ Larsen
 * Arizona Institute for Resilience
 * 
 * @todo: add a back button for anon users
 */

import React from 'react';
import { Container, Row, Col, Form, Spinner, ButtonGroup, ToggleButton, Table } from 'react-bootstrap';

const radios = [
    { name: 'Game Leaderboard', value: '1' },
    { name: 'City Leaderboards', value: '2' },
];
const INVERTED_CITIES_MAP = {
    "Tucson": 'tucson',
    "Phoenix": 'phoenix',
    "Flagstaff": 'flagstaff',
    "El Paso": 'el_paso',
    "Albuquerque": 'albuquerque'
};

export default class Leaderboards extends React.Component {
    constructor(props) {
        super(props);

        /** @TODO - move this into profile.jsx eventually */
        const today = new Date();
        const currYear = String(today.getUTCFullYear());
        const currMonth = today.getMonth() + 1; // +1 because it is 0 based
        const selectedYear = currMonth >= 7 ? currYear : String(currYear - 1);
        // if month is before july, displays results from previous year. otherwise, displays current year.

        this.state = {
            leaderboardData: [], // all user data, for usernames, estimates, points
            userStats: null, // stats for the user viewing, to display on main leaderboard
            months: this.determineMonths(currYear, selectedYear, currMonth, 'July'),
            rank: null,
            currMonth: currMonth,
            currYear: currYear,
            selMonth: "July",
            selectedYear: selectedYear,
            currCity: "Tucson",
            loading: true,
            finalLoading: true,
            radioValue: '1',
            sortSel: "Total"
        }

        document.querySelector("body").scrollTo(0, 0);
    }


    componentDidMount() {
        this.fetchData();
    }

    /**
     * fetches the leaderboard data as well as initially sorting it by year points.
     * also fetches estimate data for the specific user if there is any
     * 
     * originally was in componentDidMount but moved here to support leaderboard
     * changing between seasons
     * @param {*} selectedYear 
     */
    async fetchData(selectedYear) {
        selectedYear = selectedYear !== undefined ? selectedYear : this.state.selectedYear;
        let uid = undefined;

        // Handle case where user clicks leaderboard from the homepage.
        // TODO: handle with better routing to avoid this, but this should
        // be a temporary fix
        if (this.props.user) {
            ({ uid } = this.props.user);
        }

        //get firestore objects
        const { firestore } = this.props;
        const estimatesRef = firestore.collection('season').doc(selectedYear).collection('estimates');

        try {
            // user data
            const estimates = await estimatesRef.orderBy('year_points', 'desc').get();
            let leaderboardData = estimates.docs.map(doc => doc.data());

            // get user stats
            // NOTE: user stats will be undefined if user didnt play selected season
            const userDoc = await estimatesRef.doc(uid).get();

            // Set leaderboard data and defer to sortLeaderboard to do the
            // sorting.
            this.setState({leaderboardData, userStats: userDoc.data() });
            this.sortLeaderboard(true, null, null);
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * sets which leaderboard is being looked at (game or city)
     * @param {*} key
     * @param {*} value
     */
    setLeaderboard = (key, value) => {
        this.setState({ [key]: value })
    }

    /**
     * sorts the leaderboard to have the appropriate ranking order depending on
     * what leaderboard is being looked at.
     * for each comparison, the user's uid is appended to their value so users
     * of equal value will be sorted by uid for consistency.
     * @param {*} main
     * @param {*} city
     * @param {*} month
     */
    sortLeaderboard = (main, city, month) => {
        // format for new database
        month = month !== null ? month.toLowerCase() : null;
        city = city === null ? null : city === "El Paso" ? 'el_paso' : city.toLowerCase();

        let { leaderboardData, userStats } = this.state;
        const username = userStats === undefined ? undefined : userStats.username;

        if (main) {
            // check for "game" leaderboard. This is the page showing the top
            // 20 players for either the "total", or monthly categories.
            if (month === null || month === 'total') {
                // sort by total points
                leaderboardData = leaderboardData.sort((a, b) => b.year_points - a.year_points);
            } else {
                // sort by {month} points
                leaderboardData = leaderboardData.sort((a, b) => b[month].month_points - a[month].month_points);
            }

            let count = 1;
            // find the user in the sorted leaderboard data, and keep track of
            // their rank.
            let rankNotFound = leaderboardData.every((user) => {
                if (user.username === username) {
                    this.setState({ rank: count });
                    return false;
                }
                count++;
                return true;
            })
            if (rankNotFound) {
                // if the user was not found, set rank from null to 0 to
                // trigger a render.
                this.setState({ rank: 0 });
            }
        } else {
            // Otherwise sort by city and month. Don't need to track rank,
            // because city leaderboards will show every row.
            leaderboardData =
                leaderboardData.sort((a, b) => {
                    // have to add manual checks for users with no estimate
                    if (a[month][city] === undefined && b[month][city] === undefined) {
                        return 0;
                    } else if (a[month][city] === undefined) {
                        return 1;
                    } else if (b[month][city] === undefined) {
                        return -1;
                    }
                    return b[month][city].points - a[month][city].points;
                });
        }
        this.setState({ leaderboardData, loading: false });
    }

    /**
     * takes the current date and determines what months are eligable to be displayed.
     * @param {String} currYear 
     * @param {String} selectedYear 
     * @param {int} currMonth 
     * @param {String} displayMonth 
     * @returns {String[]} - array of months to be displayed    
     */
    determineMonths(currYear, selectedYear, currMonth, displayMonth) {
        // get number of display month
        displayMonth = new Date(Date.parse(displayMonth + " 1, 2012")).getMonth() + 1;

        if (currYear === selectedYear) {
            // current season
            if (currMonth < 7) { // currMonth is before July
                return [];
            } else if (currMonth === 7) { // currMonth is July
                return ['july'];
            } else if (currMonth === 8) { // currMonth is August
                return ['july', 'august'];
            }
        }
        // previous season or all season predictions made, display all months
        return ['july', 'august', 'september'];
    }



    render() {
        const uid = this.props.user === null ? null : this.props.user.uid;
        if (this.state.userStats !== null && this.state.rank !== null) {
            if (window.innerWidth < 768) {
                return (
                    <MobileLeaderboard
                        data={this.state}
                        uid={uid}
                        setLeaderboard={this.setLeaderboard}
                        sortLeaderboard={this.sortLeaderboard}
                    />
                )
            } else {
                return (
                    <DesktopLeaderboard
                        data={this.state}
                        uid={uid}
                        setLeaderboard={this.setLeaderboard}
                        sortLeaderboard={this.sortLeaderboard}
                    />
                )
            }
        } else {
            return (<Spinner className="d-flex justify-content-center" animation="border" variant="success" />)
        }
    }
}

function DesktopLeaderboard(props) {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const { leaderboardData, selMonth, currCity, loading, radioValue, rank, userStats, months } = props.data;
    const { setLeaderboard, sortLeaderboard, uid } = props;
    const username = userStats === undefined ? undefined : userStats.username;

    if (radioValue === '1') {
        return (
            leaderboardData.length <= 0 ? (
                <Container>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            {loading && <Spinner className="d-flex justify-content-center" animation="border" variant="success" />}
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Container>
                    <Row className="d-flex justify-content-center mt-3 pb-3">
                        <ButtonGroup toggle>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    type="radio"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={(e) => {
                                        setLeaderboard('currCity', 'Tucson')
                                        setLeaderboard('selMonth', 'July')
                                        setLeaderboard('radioValue', e.currentTarget.value)
                                        sortLeaderboard(false, 'Tucson', 'July');
                                    }}
                                    variant="warning"
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <h4>Rank by:&nbsp;</h4>
                            <Form.Group className="mt-2">
                                <Form.Control
                                    as="select"
                                    onChange={e => {
                                        setLeaderboard('sortSel', e.currentTarget.value)
                                        sortLeaderboard(true, null, e.currentTarget.value);
                                    }}
                                >
                                    <option>Total</option>
                                    {
                                        months.map((month) => {
                                            return (
                                                <option key={`option_${month}`}>
                                                    {capitalizeFirstLetter(month)}
                                                </option>)
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row
                        className="card-leaderboard mb-2 mt-1 ml-1 mr-1 pb-3 pt-3 d-flex justify-content-center"
                        xs={6}
                    >
                        <Col className="d-flex justify-content-center">
                            <h4 className="leaderboard-header-text">Position</h4>
                        </Col>
                        <Col className="d-flex justify-content-center">
                            <h4 className="leaderboard-header-text">Username</h4>
                        </Col>
                        <Col className="d-flex justify-content-center">
                            <h4 className="leaderboard-header-text">Total Points</h4>
                        </Col>
                        <Col className="d-flex justify-content-center">
                            <h4 className="leaderboard-header-text">July Points</h4>
                        </Col>
                        <Col className="d-flex justify-content-center">
                            <h4 className="leaderboard-header-text">August Points</h4>
                        </Col>
                        <Col className="d-flex justify-content-center">
                            <h4 className="leaderboard-header-text">September Points</h4>
                        </Col>
                    </Row>
                    {leaderboardData.map((doc, i) => (
                        i > 19 ? null : // show top 19
                            <Row
                                className="card-leaderboard ml-1 mr-1 pb-3 pt-3 mb-1 d-flex justify-content-center"
                                key={i}
                                xs={6}
                            >
                                <Col className="d-flex justify-content-center">
                                    <h4 style={{ color: doc.username === username ? "#3cbc74" : null }}>{i + 1}</h4>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <h4 style={{ color: doc.username === username ? "#3cbc74" : null,
                                     whiteSpace: 'nowrap',
                                     overflow: 'hidden',
                                     textOverflow: 'ellipsis',
                                     display: 'inline-block'
                                     }}>{
                                        doc.username === undefined ? <Spinner className="d-flex justify-content-center" animation="border" variant="success" /> : doc.username
                                    }</h4>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <h4 style={{ color: doc.username === username ? "#3cbc74" : null }}>{
                                        doc === undefined ? 'No Forecast' : doc.year_points.toFixed(2)
                                    }</h4>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <h4 style={{ color: doc.username === username ? "#3cbc74" : null }}>{
                                        doc.july === undefined ? 'No Forecast' : doc.july.month_points.toFixed(2)
                                    }</h4>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <h4 style={{ color: doc.username === username ? "#3cbc74" : null }}>{
                                        doc.august === undefined ? 'No Forecast' : doc.august.month_points.toFixed(2)
                                    }</h4>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <h4 style={{ color: doc.username === username ? "#3cbc74" : null }}>{
                                        doc.september === undefined ? 'No Forecast' : doc.september.month_points.toFixed(2)
                                    }</h4>
                                </Col>
                            </Row>
                    ))
                    }
                    {uid !== null && username === "" && rank >= 19 ?
                        <Row
                            className="card-leaderboard ml-1 mr-1 pb-3 pt-3 mb-1 d-flex justify-content-center"
                            xs={6}
                        >
                            <Col className="d-flex justify-content-center">
                                <Spinner className="d-flex justify-content-center" animation="border" variant="success" />
                            </Col>
                        </Row>
                        : null}
                    {uid === null || rank <= 19 ? null : // 20th (last) slot is for user
                        <Row
                            className="card-leaderboard ml-1 mr-1 pb-3 pt-3 mb-1 d-flex justify-content-center"
                            xs={6}
                        >
                            <Col className="d-flex justify-content-center">
                                <h4 style={{ color: "#3cbc74" }}>{rank}</h4>
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <h4 style={{ color: "#3cbc74" }}>{username}</h4>
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <h4 style={{ color: "#3cbc74" }}>{userStats.year_points.toFixed(2)}</h4>
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <h4 style={{ color: "#3cbc74" }}>{
                                    userStats.july === undefined ? 'No Forecast' : userStats.july.month_points.toFixed(2)
                                }</h4>
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <h4 style={{ color: "#3cbc74" }}>{
                                    userStats.august === undefined ? 'No Forecast' : userStats.august.month_points.toFixed(2)
                                }</h4>
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <h4 style={{ color: "#3cbc74" }}>{
                                    userStats.september === undefined ? 'No Forecast' : userStats.september.month_points.toFixed(2)
                                }</h4>
                            </Col>
                        </Row>}
                    <Row className="mt-5"></Row>
                </Container >
            )
        )
    } else {
        return (
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center">
                        {loading && <Spinner className="d-flex justify-content-center" animation="border" variant="success" />}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-3 pb-3">
                    <ButtonGroup toggle>
                        {radios.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                type="radio"
                                name="radio"
                                value={radio.value}
                                checked={radioValue === radio.value}
                                onChange={(e) => {
                                    setLeaderboard('radioValue', e.currentTarget.value);
                                    sortLeaderboard(true, null, null);
                                }}
                                variant="warning"
                            >
                                {radio.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <h4>Sort by:&nbsp;</h4>
                        <Form.Group className="mt-2">
                            <Form.Control
                                as="select"
                                onChange={e => {
                                    setLeaderboard('currCity', e.currentTarget.value)
                                    sortLeaderboard(false, e.currentTarget.value, selMonth);
                                }}
                            >
                                <option>Tucson</option>
                                <option>Phoenix</option>
                                <option>Flagstaff</option>
                                <option>Albuquerque</option>
                                <option>El Paso</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mt-2 ml-2">
                            <Form.Control
                                as="select"
                                onChange={e => {
                                    setLeaderboard('selMonth', e.currentTarget.value)
                                    sortLeaderboard(false, currCity, e.currentTarget.value);
                                }}
                            >{
                                    months.map((month) => {
                                        return (
                                            <option key={`option_${month}`}>
                                                {capitalizeFirstLetter(month)}
                                            </option>)
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center">
                        {loading && <Spinner className="d-flex justify-content-center" animation="border" variant="success" />}
                    </Col>
                </Row>
                <Row
                    className="card-leaderboard mb-2 mt-1 ml-1 mr-1 pb-3 pt-3 d-flex justify-content-center"
                    xs={4}
                >
                    <Col className="d-flex justify-content-center">
                        <h4 className="leaderboard-header-text">Position</h4>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <h4 className="leaderboard-header-text">Username</h4>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <h4 className="leaderboard-header-text">Month Points</h4>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <h4 className="leaderboard-header-text">Forecast (in.)</h4>
                    </Col>
                </Row>
                {
                    leaderboardData.map((doc, i) => (
                        doc[selMonth.toLowerCase()] === undefined ? (<div key={i}></div>) : (
                            <Row
                                className="card-leaderboard ml-1 mr-1 pb-3 pt-3 mb-1 d-flex justify-content-center"
                                key={i}
                                xs={4}
                            >
                                <Col className="d-flex justify-content-center">
                                    <h4 style={{ color: doc.username === username ? "#3cbc74" : null }}>{i + 1}</h4>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <h4 style={{ color: doc.username === username ? "#3cbc74" : null,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline-block"
                                    }}>{
                                        doc.username === undefined ?
                                            <Spinner className="d-flex justify-content-center" animation="border" variant="success" />
                                            : doc.username
                                    }</h4>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <h4 style={{ color: doc.username === username ? "#3cbc74" : null }}>{
                                        doc[selMonth.toLowerCase()][INVERTED_CITIES_MAP[currCity]] === undefined ? 'No Forecast' :
                                            doc[selMonth.toLowerCase()][INVERTED_CITIES_MAP[currCity]].points.toFixed(2)
                                    }</h4>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <h4 style={{ color: doc.username === username ? "#3cbc74" : null }}>{
                                        doc[selMonth.toLowerCase()][INVERTED_CITIES_MAP[currCity]] === undefined ? 'No Forecast' :
                                            doc[selMonth.toLowerCase()][INVERTED_CITIES_MAP[currCity]].estimate
                                    }</h4>
                                </Col>
                            </Row>
                        )
                    ))
                }
                <Row className="mt-5"></Row>
            </Container>)
    }
}

function MobileLeaderboard(props) {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const { leaderboardData, selMonth, currCity, loading, radioValue, rank, userStats, months } = props.data;
    const { setLeaderboard, sortLeaderboard, uid } = props;
    const username = userStats === undefined ? undefined : userStats.username;

    if (radioValue === '1') {
        return (
            leaderboardData.length <= 0 ? (
                <Row>
                    <Col className="d-flex justify-content-center">
                        {loading && <Spinner className="d-flex justify-content-center" animation="border" variant="success" />}
                    </Col>
                </Row>
            ) : (
                <Container>
                    <Row className="d-flex justify-content-center mt-3 pb-3">
                        <ButtonGroup toggle>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    type="radio"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={(e) => {
                                        setLeaderboard('currCity', 'Tucson')
                                        setLeaderboard('selMonth', 'July')
                                        setLeaderboard('radioValue', e.currentTarget.value)
                                        sortLeaderboard(false, 'Tucson', 'July');
                                    }}
                                    variant="warning"
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Form.Group className="mt-2">
                                <Form.Control
                                    as="select"
                                    onChange={e => {
                                        setLeaderboard('sortSel', e.currentTarget.value)
                                        sortLeaderboard(true, null, e.currentTarget.value);
                                    }}
                                >
                                    <option>Total</option>
                                    {
                                        months.map((month) => {
                                            return (
                                                <option key={`option_${month}`}>
                                                    {capitalizeFirstLetter(month)}
                                                </option>)
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table responsive striped bordered hover variant="dark">
                                <thead>
                                    <tr>
                                        <th style={{ color: "#ffcd60" }}>Position</th>
                                        <th style={{ color: "#ffcd60" }}>Username</th>
                                        <th style={{ color: "#ffcd60" }}>Total Points</th>
                                        <th style={{ color: "#ffcd60" }}>July Points</th>
                                        <th style={{ color: "#ffcd60" }}>August Points</th>
                                        <th style={{ color: "#ffcd60" }}>September Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaderboardData.map((doc, i) => (
                                        i > 19 ? null :
                                            <tr key={`position-${i}`}>
                                                <th style={{ color: doc.username === username ? "#3cbc74" : null }}>{i + 1}</th>
                                                <th style={{ color: doc.username === username ? "#3cbc74" : null }}>{
                                                    doc.username === undefined ?
                                                        <Spinner className="d-flex justify-content-center" animation="border" variant="success" />
                                                        : doc.username
                                                }</th>
                                                <th style={{ color: doc.username === username ? "#3cbc74" : null }}>{
                                                    doc === undefined ? 'No Forecast' : doc.year_points.toFixed(2)
                                                }</th>
                                                <th style={{ color: doc.username === username ? "#3cbc74" : null }}>{
                                                    doc.july === undefined ? 'No Forecast' : doc.july.month_points.toFixed(2)
                                                }</th>
                                                <th style={{ color: doc.username === username ? "#3cbc74" : null }}>{
                                                    doc.august === undefined ? 'No Forecast' : doc.august.month_points.toFixed(2)
                                                }</th>
                                                <th style={{ color: doc.username === username ? "#3cbc74" : null }}>{
                                                    doc.september === undefined ? 'No Forecast' : doc.september.month_points.toFixed(2)
                                                }</th>
                                            </tr>
                                    ))
                                    }
                                    {uid !== null && username === "" && rank >= 19 ?
                                        <tr>
                                            <th>
                                                <Spinner className="d-flex justify-content-center" animation="border" variant="success" />
                                            </th>
                                        </tr> : null}
                                    {uid === null || username === "" || rank <= 19 ? null :
                                        <tr>
                                            <th style={{ color: "#3cbc74" }}>{rank}</th>
                                            <th style={{ color: "#3cbc74" }}>{username}</th>
                                            <th style={{ color: "#3cbc74" }}>{userStats.year_points.toFixed(2)}</th>
                                            <th style={{ color: "#3cbc74" }}>{
                                                userStats.july === undefined ? 'No Forecast' : userStats.july.month_points.toFixed(2)
                                            }</th>
                                            <th style={{ color: "#3cbc74" }}>{
                                                userStats.august === undefined ? 'No Forecast' : userStats.august.month_points.toFixed(2)
                                            }</th>
                                            <th style={{ color: "#3cbc74" }}>{
                                                userStats.september === undefined ? 'No Forecast' : userStats.september.month_points.toFixed(2)
                                            }</th>
                                        </tr>}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="mt-5"></Row>
                </Container >
            )
        )
    } else {
        return (
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center">
                        {loading && <Spinner className="d-flex justify-content-center" animation="border" variant="success" />}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-3 pb-3">
                    <ButtonGroup toggle>
                        {radios.map((radio, idx) => (
                            <ToggleButton
                                key={idx}
                                type="radio"
                                name="radio"
                                value={radio.value}
                                checked={radioValue === radio.value}
                                onChange={(e) => {
                                    setLeaderboard('radioValue', e.currentTarget.value)
                                    sortLeaderboard(true, null, null);
                                }}
                                variant="warning"
                            >
                                {radio.name}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Form.Group className="mt-2">
                            <Form.Control
                                as="select"
                                onChange={e => {
                                    setLeaderboard('currCity', e.currentTarget.value)
                                    sortLeaderboard(false, e.currentTarget.value, selMonth);
                                }}
                            >
                                <option>Tucson</option>
                                <option>Phoenix</option>
                                <option>Flagstaff</option>
                                <option>Albuquerque</option>
                                <option>El Paso</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <Form.Group className="mt-2">
                            <Form.Control
                                as="select"
                                onChange={e => {
                                    setLeaderboard('selMonth', e.currentTarget.value)
                                    sortLeaderboard(false, currCity, e.currentTarget.value);
                                }}
                            >
                                {
                                    months.map((month) => {
                                        return (
                                            <option key={`option_${month}`}>
                                                {capitalizeFirstLetter(month)}
                                            </option>)
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center">
                        {loading && <Spinner className="d-flex justify-content-center" animation="border" variant="success" />}
                    </Col>
                </Row>
                <Table responsive striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th style={{ color: "#ffcd60" }}>Position</th>
                            <th style={{ color: "#ffcd60" }}>Username</th>
                            <th style={{ color: "#ffcd60" }}>Points</th>
                            <th style={{ color: "#ffcd60" }}>Forecast</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leaderboardData.map((doc, i) => (
                            doc[selMonth.toLowerCase()] === undefined ? (<div key={i}></div>) :
                                <tr key={i} >
                                    <th style={{ color: doc.username === username ? "#3cbc74" : null }}>{i + 1}</th>
                                    <th style={{ color: doc.username === username ? "#3cbc74" : null }}>{
                                        doc.username === undefined ?
                                            <Spinner className="d-flex justify-content-center" animation="border" variant="success" />
                                            : doc.username
                                    }</th>
                                    <th style={{ color: doc.username === username ? "#3cbc74" : null }}>{doc[selMonth.toLowerCase()][INVERTED_CITIES_MAP[currCity]] === undefined ? 'No Forecast' :
                                        doc[selMonth.toLowerCase()][INVERTED_CITIES_MAP[currCity]].points.toFixed(2)}</th>
                                    <th style={{ color: doc.username === username ? "#3cbc74" : null }}>{doc[selMonth.toLowerCase()][INVERTED_CITIES_MAP[currCity]] === undefined ? 'No Forecast' :
                                        `${doc[selMonth.toLowerCase()][INVERTED_CITIES_MAP[currCity]].estimate}"`}</th>
                                </tr>
                        ))
                        }
                    </tbody>
                </Table>
            </Container>)
    }
}

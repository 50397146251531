/**
 * Author: Lily McMullen
 * Arizona Institute for Resilience
 * Data for resources.jsx component, for the monsoon resources page.
 */
export const tagColors = {
    Article: "#6A5ACD",   // Slate blue
    Podcast: "#FF6347",   // Tomato
    Blog: "#DA70D6",   // Orchid
    Paper: "#B59410", // Gold
    Mapping: "#FF4500", // Orange
    Climate: "#6DB65B",  // Olive
    Weather: "#4682B4", // Steel blue
    Arizona: "#BF0A30", // Bright red
    "New Mexico": "#00796B" // Teal
};

export const resources = [
    
    {
        title: "Monsoon",
        url: "https://www.weather.gov/twc/Monsoon",
        description: "Monsoon Landing Page for the National Weather Service - Tucson office",
        tags: ["Weather", "Arizona"]
    },
    {
        title: "Monsoon Information Index",
        url: "https://www.weather.gov/twc/MonsoonInfo",
        description: "A comprehensive list of short articles explaining different aspects of the SW Monsoon.",
        tags: ["Article", "Climate"]
    },
    {
        title: "Arizona and the North American Monsoon System",
        url: "https://extension.arizona.edu/sites/extension.arizona.edu/files/pubs/az1417.pdf",
        description: "UA Cooperative Extension Article by Mike Crimmins (CALS Climate Extension Specialist and CLIMAS PI)",
        tags: ["Article", "Climate"]
    },
    {
        title: "The Southwest Climate Podcast",
        url: "https://climas.arizona.edu/podcasts",
        description: "Monthly podcast by climate experts that discusses important climate-related issues, including monsoons and other factors that influence monsoons, in the Southwest.",
        tags: ["Podcast", "Climate"]
    },
    {
        title: "MadWeather Blog",
        url: "https://madweather.blogspot.com/",
        description: "A blog about Southwestern weather, weather forecasting & observing, and other related topics",
        tags: ["Blog", "Weather"]
    },
    {
        title: "SW Climate - Monsoon",
        url: "https://climas.arizona.edu/sw-climate/monsoon",
        description: "Informational site on the Southwest Monsoon",
        tags: ["Article", "Climate"]
    },
    {
        title: "Large-Scale Patterns Associated with Severe Summertime Thunderstorms over Central Arizona",
        url: "https://journals.ametsoc.org/view/journals/wefo/10/4/1520-0434_1995_010_0763_lspaws_2_0_co_2.xml",
        description: "Paper on the Monsoon which identified three distinct large-scale patterns (types I, II, and III)",
        tags: ["Paper", "Weather"]
    },
    {
        title: "The North American Monsoon",
        url: "https://www.climate.gov/news-features/blogs/enso/north-american-monsoon",
        description: "A blog mostly about monitoring and forecasting El Niño, La Niña, and their regional and global impacts...but sometimes about other climate phenomena that influence seasonal climate",
        tags: ["Blog", "Climate"]
    },
    {
        title: "Weather101: The North American Monsoon",
        url: "https://www.weather.gov/media/ohx/PDF/Weather101Monsoon.pdf",
        description: "Presentation slide deck from the Weather101 series",
        tags: ["Weather"]
    },
    {
        title: "The North American Monsoon",
        url: "https://www.cpc.ncep.noaa.gov/products/outreach/Report-to-the-Nation-Monsoon_aug04.pdf",
        description: "Reports to the Nation - Outreach document on the monsoon",
        tags: ["Paper", "Climate"]
    },
    {
        title: "Summer Severe Thunderstorm Patterns in Arizona",
        url: "https://www.weather.gov/media/twc/monsoon/monsoon_patterns.pdf",
        description: "Short summary of the Maddox paper",
        tags: ["Paper"]
    },
    {
        title: "North American Monsoon Highlights",
        url: "https://www.weather.gov/abq/northamericanmonsoon-intro",
        description: "Monsoon Highlights Page for the National Weather Service - Albuquerque office",
        tags: ["Weather", "New Mexico"]
    },
    {
        title: "Synoptic and Mesoscale Environment of Convection during the North American Monsoon across Central and Southern Arizona",
        url: "https://journals.ametsoc.org/view/journals/wefo/32/2/waf-d-15-0098_1.xml",
        description: "Paper that comprehensively analyzes the synoptic and mesoscale environment associated with North American monsoon–related thunderstorms affecting central and southern Arizona.",
        tags: ["Paper", "Arizona"]
    },
    {
        title: "The North American Monsoon",
        url: "https://journals.ametsoc.org/view/journals/bams/78/10/1520-0477_1997_078_2197_tnam_2_0_co_2.xml",
        description: "Paper that reviews the wide range of past and current research dealing with the meteorological and climatological aspects of the North American monsoon, highlighting historical development and major research themes.",
        tags: ["Paper", "Climate"]
    },
    {
        title: "Monsoon Forecast Discussions",
        url: "https://arizonawrfdiscussions.blogspot.com/",
        description: "Blog from the University of Arizona - Hydrology and Atmospheric Sciences during the monsoon season",
        tags: ["Weather", "Blog"]
    },
    {
        title: "Southwest U.S. Summer Monsoon Season Precipitation Mapping",
        url: "https://cales.arizona.edu/climate/misc/SWMonsoonMaps/current/swus_monsoon.html",
        description: "Maps focused on capturing unique elements of the summer monsoon season hydroclimate across Arizona and New Mexico including total precipitation, intensity, and frequency of precipitation events.",
        tags: ["Mapping", "Arizona", "New Mexico"]
    },
    {
        title: "RainLog",
        url: "https://rainlog.org/map#9.42/32.2976/-110.927",
        description: "Rainlog.org is a cooperative rainfall monitoring network for Arizona.",
        tags: ["Mapping", "Arizona"]
    },
    {
        title: "Three-Month Outlooks - Official Forecasts",
        url: "https://www.cpc.ncep.noaa.gov/products/predictions/long_range/seasonal.php?lead=1",
        description: "Official 90-day Outlooks issued once each month near mid-month at 8:30am Eastern Time from NOAA - NWS - Climate Prediction Center",
        tags: ["Arizona", "New Mexico"]
    },
    {
        title: "Forecast Outlooks for the Monsoon Fantasy Game", 
        url: "https://monsoonfantasy.arizona.edu/outlooks", 
        description: "This page contains five different types of rainfall forecast outlooks and probabilities of wet, dry, or average conditions in each of the five cities for the Monsoon Fantasy Game.",
        tags: ["Arizona", "New Mexico"]
    },
    {
        title: "Monsoon Watch 2024 - A KGUN 9 Original", 
        url: "https://www.kgun9.com/monsoon2024",
        description: "A 30 minute series of news stories and meteorologist weather forecasts to introduce the 2024 Monsoon Season. This video addresses important aspects of the monsoon season including La Niña/El Niño, dangers of the monsoon, flooding prevention, and more.", 
        tags: ["Arizona", "Weather"]
    }, 
    {
        title: "CoCoRaHS - New Mexico",
        url: "https://www.cocorahs.org/Maps/ViewMap.aspx?state=NM",
        description: "CoCoRaHS (Community Collaborative Rain, Hail and Snow Network) is a unique, non-profit, community-based network of volunteers of all ages and backgrounds working together to measure and map precipitation (rain, hail and snow).",
        tags: ["Mapping", "Arizona"]
    },
    {
        title: "UArizona Weather Forecasts",
        url: "https://weather.arizona.edu",
        description: "Plots and graphics generated from the past three days of UA-WRF weather forecasts. Also features cloud camera footage and other UArizona weather-related resources.",
        tags: ['Arizona', 'New Mexico', 'Weather']
    }
]
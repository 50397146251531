/**
 * NavBar.jsx
 * Represents the navigation bar at the top of the homescreen
 * Author: Benni Delgado
 * AIR
 */
import React, { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import $ from "jquery";
//media
import Logo from '../media/airLogo.png';



export default function NavBar(props) {
    // on mount
    useEffect(() => {
        // add an event listenenr to change the opacity when scrolled
        window.addEventListener('scroll', changeBlur, true);

        // dismount, removing the listener
        return () => {
            window.removeEventListener('scroll', changeBlur)
        }
    }, []);

    // state to keep track of whether or not the collapable menu is visible,
    // i tried to do this with jquery but this is cleaner
    const [collapsed, setCollapsed] = useState(true);

    /**
     * Changes the blurriness of the navbar based on the scroll distance from the top
     * Navbar will be transparent at the top of the page and blur as you scroll
     * down, with a max bluriness of 20px
     * @param {UIEvent} e
     */
    const changeBlur = (e) => {
        // get the navbar
        const navbar = $('#top-navbar.navbar');
        const dy = document.body.scrollTop;

        // add the effects only if we are scrolled
        if(dy < 1 && navbar.hasClass('blur-navbar')) {
            navbar.removeClass('blur-navbar');
        } else if (dy >= 1 && !navbar.hasClass('blur-navbar')) {
            navbar.addClass('blur-navbar');
        }
    }

    /**
     * Smooth scrolls to the clicked element
     * @param {HTMLElement} e 
     */
    const moveTo = async (e) => {
        //get the id of the element, slicing the 'text-' part
        const id = e.target.id.slice(5);
        if (props.history.location !== "/home") {
            await props.history.push("/home");
        }
        try {
            document.querySelector(`#${id}`).scrollIntoView({
                behavior: 'smooth'
            });
        } catch (e) {
            //sometimes the selector will not load in time, catch the error just in case
            console.error(e);
        }
    }

    /**
     * Blurs the background of the navbar if the collapsable menu is visible.
     * This is neccesary because if we are at the top of the screen there is no
     * blurred background, but if the user clicks and opens the menu, its hard
     * to read the menu when theres no background
     */
    const handleHamburgerClick = () => {
        setCollapsed(!collapsed);
        const dy = document.body.scrollTop;
        const navbar = $("#top-navbar.navbar");
        // we only want this function to trigger if the navbar is at the top
        if(dy >= 2) {
            return;
        }
        
        if(collapsed) {
            // if the nav is being opened
            navbar.addClass("blur-navbar");
        } else if(!collapsed) {
            // if the navbar is being collapsed
            navbar.removeClass("blur-navbar");
        }
    }


    return (
        <Navbar id="top-navbar" collapseOnSelect expand="lg" fixed="top" variant="dark">
            <Navbar.Brand className="navbar-brand" href="https://air.arizona.edu">
                <img
                    className="logo-img"
                    src={Logo}
                    alt="AIR Logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle onClick={handleHamburgerClick} aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className="justify-content-end color-nav" id="responsive-navbar-nav">
                <Nav activeKey="/home">
                    <Nav.Item>
                        <Nav.Link onClick={e => moveTo(e)}>
                            <h4 id="text-purpose">Purpose</h4>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={e => moveTo(e)}>
                            <h4 id="text-overview">Overview</h4>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={e => moveTo(e)}>
                            <h4 id="text-works">How it Works</h4>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={e => moveTo(e)}>
                            <h4 id="text-point">Scoring</h4>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={e => moveTo(e)}>
                            <h4 id="text-group">Groups</h4>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={props.signIn}>
                            <h4 id="text-login" style={{ color: "#3CBC74" }}>
                                {props.user ? (
                                    "Play"
                                ) : (
                                    "Log In"
                                )}
                            </h4>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={() => props.history.push('/leaderboards')}>
                            <h4 style={{ color: "#ffcd60" }} id="leaderboards">Leaderboard</h4>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

import React from 'react';
import { Row, Col, Accordion, Card } from 'react-bootstrap';

class Collapse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open ?? false,
        }
    }
    render(){
        return (
            <Accordion as={Card} className={this.props.containerClass ?? "bg-dark text-white my-3 mx-0"} defaultActiveKey={this.state.open ? '0' : null }>
                <Accordion.Toggle 
                    as={Card.Header} 
                    eventKey="0" 
                    style={{cursor: 'pointer'}} 
                    className={this.props.titleContainerClass ?? "m-0 p-3 border-dark"}
                    onClick={()=>this.state.open ? this.setState({open: false}) : this.setState({open: true})}
                >
                    <Row className={this.props.titleClass ?? "p-0 m-0 col-12"}>
                        <Col style={{maxWidth: '20px'}} className="p-0">{ this.state.open ? '−' : '+' }</Col>
                        <Col className="p-0">{this.props.title}</Col>
                    </Row>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0"  className={this.props.collapseContainerClass ?? null}>
                    <div className={this.props.className}>{this.props.children}</div>
                </Accordion.Collapse>
            </Accordion>
        )
    }
}

export { Collapse }
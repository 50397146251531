/**
 * monthlyDigest.jsx: generates a histgram of estimates for a chosen month for
 *                    for each city. also provides mean and median stats for 
 *                    each city/month combination.
 * 
 * Author: CJ Larsen; edited by Roger Palmenberg
 * Arizona Institute for Resilience
 */

import React from 'react';
import * as d3 from 'd3';
import { motion } from 'framer-motion';
import { Row, Container, Col, Form, Spinner } from 'react-bootstrap';
import { DigestHistogram } from './visualizations';

const CITIES_MAP = {
    tucson: "Tucson",
    phoenix: "Phoenix",
    flagstaff: "Flagstaff",
    el_paso: "El Paso",
    albuquerque: "Albuquerque"
};

export default class Digest extends React.Component {

    constructor(props) {
        super(props);

        const today = new Date();
        const currYear = String(today.getUTCFullYear());
        const currMonth = today.getMonth() + 1;

        // set to last season in case next check doesn't go through
        let selectedYear = (today.getFullYear() - 1).toString();

        if (currMonth >= 6) { // if its June of current year, set selectedYear to current year
            selectedYear = currYear;
        }

        // programmatic solution for keeping track of years
        var years = [];
        // 2021 was the first season of the game, so it's our starting point
        // selectedYear should be equal to currYear if 
        for(let i = selectedYear; i >= 2021; i--){
            years.push(i);
        }

        this.state = {
            displayMonth: 'July',
            currMonth: currMonth,
            selectedYear: selectedYear,
            currYear: currYear,
            months: this.determineMonths(currYear, selectedYear, currMonth, 'July'),
            data: [], // all user data, to get their estimates
            stats: {}, // summary data for user estimates
            rainData: {}, // actual rainfall data
            years: years, // years list built in for loop above (now doesn't need to be altered each new season)
        };

        document.querySelector("body").scrollTo(0, 0);
    }

    async componentDidMount() {
        this.fetchData();
    }

    /**
     * fetchData(year, newMonths)
     * 
     * fetches the required data (user, rain) to create the vizzis. takes in optional
     * params depending on if it called on component mount or not
     * 
     * @param {String} year - selected year to display
     * @param {String[]} newMonths - selected months to display
     */
    async fetchData(year, newMonths) {
        const months = newMonths !== undefined ? newMonths : this.state.months;
        const selectedYear = year !== undefined ? year : this.state.selectedYear;

        //get firestore objects
        const { firestore } = this.props;
        const estimatesRef = firestore.collection('season').doc(selectedYear).collection('estimates');
        const rainStatsRef = firestore.collection('rainStatistics').doc(selectedYear);

        try {
            // user data
            const estimates = await estimatesRef.get();
            const data = estimates.docs.map(doc => doc.data());

            // rain data
            const rainStats = await rainStatsRef.get();
            const rainData = rainStats.data();

            /** @TODO - move this into google function/read from firestore instead? */
            let stats = {};
            const forecastData = {
                july: {
                    tucson: [],
                    phoenix: [],
                    flagstaff: [],
                    el_paso: [],
                    albuquerque: []
                },
                august: {
                    tucson: [],
                    phoenix: [],
                    flagstaff: [],
                    el_paso: [],
                    albuquerque: []
                },
                september: {
                    tucson: [],
                    phoenix: [],
                    flagstaff: [],
                    el_paso: [],
                    albuquerque: []
                }
            };

            // get all the estimates made for each city/month combo
            data.forEach((user) => {
                months.forEach((month) => {
                    Object.keys(CITIES_MAP).forEach((city) => {
                        if (user[month][city] !== undefined) {  // esimate exists
                            forecastData[month][city].push(user[month][city].estimate);
                        }
                    })
                })
            })
            // get the summary stats for each city/month combo
            months.forEach((month) => {
                stats[month] = {};
                Object.keys(CITIES_MAP).forEach((city) => {
                    stats[month][city] = {
                        mean: Math.round(d3.mean(forecastData[month][city]) * 100) / 100,
                        median: Math.round(d3.median(forecastData[month][city]) * 100) / 100,
                        total: forecastData[month][city].length
                    };
                })
            })

            this.setState({ data, rainData, stats });
        } catch (e) {
            console.error(e);
        }

    }

    /**
     * determineMonths(currYear, selectedYear, currMonth, displayMonth)
     * 
     * takes the current date and determines what months are eligable to be displayed.
     * 
     * @param {String} currYear 
     * @param {String} selectedYear 
     * @param {int} currMonth 
     * @param {String} displayMonth 
     * @returns {String[]} - array of months to be displayed
     */
    determineMonths(currYear, selectedYear, currMonth, displayMonth) {
        // get number of display month
        displayMonth = new Date(Date.parse(displayMonth + " 1, 2012")).getMonth() + 1;

        if (currYear === selectedYear) {
            // current season
            if(currMonth < 6){ //currMonth is before June, don't show any months
                return [];
            } else if (currMonth === 6) { // currMonth is June
                return ['july'];
            } else if (currMonth === 7) { // currMonth is July
                return ['july','august'];
            } else if (currMonth === 8) { // currMonth is August
                return ['july', 'august', 'september'];
            }
        }
        // previous season or all season predictions made, display all months
        return ['july', 'august', 'september'];
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render() {
        const { currYear, selectedYear, displayMonth, currMonth, data, stats, rainData, months } = this.state;

        const mobile = window.innerWidth < 768;
        const chartWidth = mobile ? window.innerWidth - 100 : 525;
        const chartHeight = chartWidth / 1.5;

        return (
            <>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Form.Group className="mt-2 mr-2">
                            <Form.Control // selection for month on histograms
                                as="select"
                                onChange={e => this.setState({
                                    displayMonth: e.target.value
                                })}
                            >{
                                    months.map((month) => {
                                        return (
                                            <option key={`option_${month}`}>
                                                {this.capitalizeFirstLetter(month)}
                                            </option>)
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Control // selection for year on histograms
                                as="select"
                                onChange={
                                    e => {
                                        const newMonths = this.determineMonths(currYear, e.target.value, currMonth, displayMonth);
                                        this.fetchData(e.target.value, newMonths);
                                        this.setState({
                                            selectedYear: e.target.value,
                                            months: newMonths
                                        });
                                    }
                                }
                            >
                                {/* year select option is now based on programmatic list of years above */}
                                {currMonth > 7 ? <option>{currYear}</option> : null}
                                {this.state.years.map((year) => (
                                    <option key={year}>{year}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <motion.div animate={{ opacity: [0, 1] }} className="card-large">
                    <Container fluid>
                        <Row className="d-flex justify-content-center" style={{ padding: "1%" }}>
                            <Col className="d-flex justify-content-center">
                                <h4>The figures below show the number of forecasts (y-axis) and their rainfall value (x-axis) made by the participants for the selected year and month.</h4>
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
                {data.length === 0 ?
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Spinner className="d-flex justify-content-center" animation="border" variant="success" />
                        </Col>
                    </Row>
                    :
                    <>
                        <Row xs={1} md={2} className="justify-content-center" style={{ padding: "1%" }}>
                            <Col className="d-flex justify-content-center">
                                <ForecastCard
                                    width={chartWidth}
                                    height={chartHeight}
                                    displayMonth={displayMonth}
                                    selectedYear={selectedYear}
                                    city={'tucson'}
                                    data={data}
                                    stats={stats}
                                    rainData={rainData}
                                    mobile={mobile}
                                />
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <ForecastCard
                                    width={chartWidth}
                                    height={chartHeight}
                                    displayMonth={displayMonth}
                                    selectedYear={selectedYear}
                                    city={'phoenix'}
                                    data={data}
                                    stats={stats}
                                    rainData={rainData}
                                    mobile={mobile}
                                />
                            </Col>
                        </Row>
                        <Row xs={1} md={2} className="justify-content-center" style={{ padding: "1%" }}>
                            <Col className="d-flex justify-content-center">
                                <ForecastCard
                                    width={chartWidth}
                                    height={chartHeight}
                                    displayMonth={displayMonth}
                                    selectedYear={selectedYear}
                                    city={'flagstaff'}
                                    data={data}
                                    stats={stats}
                                    rainData={rainData}
                                    mobile={mobile}
                                />
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <ForecastCard
                                    width={chartWidth}
                                    height={chartHeight}
                                    displayMonth={displayMonth}
                                    selectedYear={selectedYear}
                                    city={'el_paso'}
                                    data={data}
                                    stats={stats}
                                    rainData={rainData}
                                    mobile={mobile}
                                />
                            </Col>
                        </Row>
                        <Row xs={1} md={2} className="justify-content-center" style={{ padding: "1%" }}>
                            <Col className="d-flex justify-content-center">
                                <ForecastCard
                                    width={chartWidth}
                                    height={chartHeight}
                                    displayMonth={displayMonth}
                                    selectedYear={selectedYear}
                                    city={'albuquerque'}
                                    data={data}
                                    stats={stats}
                                    rainData={rainData}
                                    mobile={mobile}
                                />
                            </Col>
                        </Row>
                    </>
                }
            </>
        )
    }
}

function ForecastCard(props) {
    const { width, height, displayMonth, selectedYear, city, data, stats, rainData, mobile } = props;
    return (
        <motion.div animate={{ opacity: [0, 1] }} className="card-large">
            <Container fluid>
                <Row className="d-flex align-content-center" style={{ padding: "1%" }}>
                    <Col className="d-flex justify-content-center">
                        <h3>{CITIES_MAP[city]}</h3>
                    </Col>
                </Row>
                { stats[displayMonth.toLowerCase()][city] && stats[displayMonth.toLowerCase()][city].total > 0 ?
                <Row className="d-flex align-content-center" style={{ padding: "1%" }}>
                    <Col className="d-flex justify-content-center">
                        <DigestHistogram
                            width={width}
                            height={height}
                            displayMonth={displayMonth.toLowerCase()}
                            selectedYear={selectedYear}
                            city={city}
                            data={data}
                            mobile={mobile}
                        />
                    </Col>
                </Row> :
                <Row>
                    <Col className="text-white text-center p-3">
                        No forecasts have been made yet.
                    </Col>
                </Row>
                }
                <Row xs={1} md={2} style={{ padding: "1%" }}>
                    <Col>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <h4 className="whitespace"><u>Forecasts</u></h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                {stats[displayMonth.toLowerCase()] === undefined ? <h4>Mean: </h4> :
                                    <h4>Mean: {isNaN(stats[displayMonth.toLowerCase()][city].mean) ? 'N/A' : `${stats[displayMonth.toLowerCase()][city].mean}"`}</h4>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                {stats[displayMonth.toLowerCase()] === undefined ? <h4>Median: </h4> :
                                    <h4>Median: {isNaN(stats[displayMonth.toLowerCase()][city].median) ? 'N/A' : `${stats[displayMonth.toLowerCase()][city].median}"`}</h4>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                {stats[displayMonth.toLowerCase()] === undefined ? <h4>Total: </h4> :
                                    <h4>Total: {stats[displayMonth.toLowerCase()][city].total == 0 ? 'N/A' : stats[displayMonth.toLowerCase()][city].total }</h4>}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <h4><u>Historical Observations</u></h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <h4>Mean: {Math.round(rainData[CITIES_MAP[city]][displayMonth].mean * 100) / 100}"</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <h4>Median: {Math.round(rainData[CITIES_MAP[city]][displayMonth].median * 100) / 100}"</h4>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </motion.div>
    )

}
/**
 * Author: Lily McMullen
 * Arizona Institute for Resilience
 * Monsoon resources pages
 */
import React from 'react';
import { motion } from 'framer-motion'; // for animations
import { Row, Container, Col, Card, Button, Badge } from 'react-bootstrap'; // bootstrap components for layout and UI
import { resources, tagColors } from '../data/resourcesData'; // resource data and tag colors

export default class Resources extends React.Component {
    state = {
        activeTags: [] // state to keep track of which tags are active
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    // Method to tag filters on/off
    toggleTagFilter = (tag) => {
        const { activeTags } = this.state;
        if (activeTags.includes(tag)) {
            this.setState({ activeTags: activeTags.filter(t => t !== tag) });
        } else {
            this.setState({ activeTags: [...activeTags, tag] });
        }
    };

    // Method to filter resources based on active tags
    filterResourcesByTag = () => {
        const { activeTags } = this.state;
        return resources.filter(resource => 
            activeTags.length === 0 || resource.tags.some(tag => activeTags.includes(tag))
        );
    };

    // Render method for displaying the component
    render() {
        const allTags = Object.keys(tagColors);
        const filteredResources = this.filterResourcesByTag();

        return (
            <motion.div animate={{ opacity: [0, 1] }} className="resources-container p-4">
                <Container fluid>
                    <Row className="text-center mb-4">
                        {/* Displaying filter buttons for each tag */}
                        <Col className="d-flex flex-wrap justify-content-center col-12">
                            {allTags.map(tag => (
                                <Button 
                                key={tag} 
                                className={`button-tag ${this.state.activeTags.includes(tag) ? 'active' : 'inactive'}`}
                                style={{ backgroundColor: this.state.activeTags.includes(tag) ? tagColors[tag] : undefined }}
                                onClick={() => this.toggleTagFilter(tag)}
                            >
                                {tag}
                            </Button>                            
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        {/* Mapping over filtered resources to display them */}
                        {filteredResources.map((resource, index) => (
                            <Col md={4} sm={6} key={index} className="mb-4">
                                <Card className="resource-card h-100">
                                    <Card.Body>
                                        <Card.Title>
                                            <a rel="noopener noreferrer" target="_blank" href={resource.url}>
                                                {resource.title}
                                            </a>
                                        </Card.Title>
                                        <Card.Text>
                                            {resource.description}
                                        </Card.Text>
                                        <div className="mt-2">
                                            {resource.tags.map((tag, idx) => (
                                                <Badge key={idx} pill style={{ backgroundColor: tagColors[tag], margin: '2px', color: 'white', cursor: 'pointer' }}
                                                onClick={() => this.toggleTagFilter(tag)} // onClick handler so clicking badges within resource cards also activates filter
                                                >
                                                    {tag}
                                                </Badge>
                                            ))}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </motion.div>
        );
    }
}
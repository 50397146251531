/**
 * visualizations.jsx
 * Various visualization components for the monsoon game
 * Authors: CJ Larsen, Benni Delgado
 * Arizona Institute for Resilience
 */

import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import historicalcleaned from '../data/historicalcleaned.json';

const CITIES_MAP = {
    tucson: "Tucson",
    phoenix: "Phoenix",
    flagstaff: "Flagstaff",
    el_paso: "El Paso",
    albuquerque: "Albuquerque"
}

function EstimateHistogram(props) {
    //  mainly copied from above
    let { width, height, currCity, currMonth, estimate, rainData, mobile } = props; // do this better bud

    const dataLoaded = Object.keys(rainData).length !== 0;
    const mean = dataLoaded ? rainData[CITIES_MAP[currCity]][currMonth].mean : 0;
    const median = dataLoaded ? rainData[CITIES_MAP[currCity]][currMonth].median : 0;

    function getData() {
        const data = [];
        /**
         * We don't have data for all of the months, just monsoon, so if it's
         * currently not a monsoon month, the distribution will be the total
         * for all the monsoon months. Otherwise its just whatever month it is
         * @TODO maybe find a new dataset or restrict the estimate page.
         */
        const month = ["June", "July", "August", "September"].includes(currMonth) ? currMonth : "Total";
        for (const cities of Object.values(historicalcleaned)) {
            /** @todo: set up dynamically once guessing system is established */
            data.push(cities[CITIES_MAP[currCity]][month])
        }
        return data;
    }

    //get the data
    const histData = getData();
    // Add 1 to give players the opportunity to geuss high
    const max = Math.ceil(d3.max(histData)) + 1;
    const ref = useRef(null);
    const margin = { top: 10, right: 25, bottom: 30, left: 20 }

    let thresholds = [];
    for (let i = 0; i <= max; i += 0.25) {
        thresholds.push(i)
    }

    const testbin = d3.bin().thresholds(thresholds)
    const testbuckets = testbin(histData)

    testbuckets[0].x0 = 0;
    testbuckets[testbuckets.length - 1].x1 = parseFloat(max)

    // scales
    const xScale = d3.scaleLinear()
        .domain([0, testbuckets[testbuckets.length - 1].x1])
        .range([margin.left, width - margin.right])
    const yScale = d3.scaleLinear()
        .domain([0, d3.max(testbuckets, d => d.length)])
        .range([height - margin.bottom, margin.top])

    // axis
    const xAxis = d3.axisBottom(xScale).ticks(7);
    const yAxis = d3.axisLeft(yScale).ticks(7);

    // max y for given city
    const yMax = d3.max(testbuckets, d => d.length);

    // initial load of webpage
    useEffect(
        () => {
            if (ref.current) {
                d3.select('#svg').remove();
                const svg = d3.select(ref.current)
                    .attr('width', width + margin.left + margin.right)
                    .attr('height', height + margin.top + margin.bottom)
                    .append('g')
                    .attr('transform', `translate(${margin.left}, ${margin.top})`)
                    .attr('id', 'svg')

                // add the axes
                svg.append('g')
                    .attr('class', 'axisWhite')
                    .attr('id', 'xaxis')
                    .attr('transform', `translate(0, ${height - margin.bottom})`)
                    .call(xAxis);
                svg.append('g')
                    .attr('class', 'axisWhite')
                    .attr('id', 'yaxis')
                    .attr('transform', `translate(${margin.left}, 0)`)
                    .call(yAxis);

                /**
                 * @TODO : make the coordinated for labels dynamic with the width
                 */
                // labels
                svg.append("text")
                    .attr('class', mobile ? 'axisLabel-mobile' : 'axisLabel')
                    .attr("x", (mobile ? width - (width / 2) - 75 : 220))
                    .attr("y", (mobile ? height + 10 : 420))
                    .text(`Rainfall (in inches)`)
                svg.append("text")
                    .attr('class', mobile ? 'axisLabel-mobile' : 'axisLabel')
                    .attr("x", -5)
                    .attr("y", height - (height / 2) + (mobile ? 80 : 100))
                    .attr("transform", `rotate(270, -5, ${height - (height / 2) + (mobile ? 80 : 100)})`)
                    .text(`Number of Occurences`)

            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [width]
    )
    // update histogram every time a new city is picked
    // also when rainData changes (for initial load in)
    useEffect(() => {
        if (ref.current) {
            d3.select('#svg').selectAll('circle').remove();
            // get new city data
            let thresholds = [];
            for (let i = 0; i <= max; i += 0.25) {
                thresholds.push(i)
            }

            const testbin = d3.bin().thresholds(thresholds)
            const testbuckets = testbin(histData)

            testbuckets[0].x0 = 0;
            testbuckets[testbuckets.length - 1].x1 = parseFloat(max);

            //update the scales
            xScale.domain([0, testbuckets[testbuckets.length - 1].x1]);
            yScale.domain([0, yMax]);

            //update the axes
            d3.select('#svg').select('#xaxis')
                .transition().duration(1000)
                .call(d3.axisBottom(xScale).ticks(7))
            d3.select('#svg').select('#yaxis')
                .transition().duration(1000)
                .call(d3.axisLeft(yScale).ticks(7))

            const oldBars = d3.select('#svg')
                .selectAll('rect')
                .data(testbuckets)
            const rectWidth = xScale(0.25) - xScale(0)

            // add new rects and update existing ones
            oldBars.enter().append('rect')
                .attr('y', height - margin.bottom)
                .attr('x', width - margin.right)
                .attr('width', rectWidth)
                .attr('height', 0)
                .merge(oldBars)
                .transition().duration(2000)
                .attr('y', d => yScale(d.length) - 1)
                .attr('x', d => xScale(d.x0) + 2)
                .attr('width', rectWidth)
                .attr('height', d => height - margin.bottom - yScale(d.length))
                .attr('stroke', 'black')
                .attr('stroke-width', 1)
                .attr('fill', '#3AABFF')

            // remove extra rects
            oldBars.exit()
                .transition().duration(2000)
                .attr('height', 0)
                .attr('width', 0)
                .attr('y', height - margin.bottom)
                .attr('x', width - margin.right)
                .remove()

            //remove old vizline
            d3.select("#svg").selectAll('.viz-line')
                .attr('stroke-width', 2)
                .attr('stroke-dasharray', '1000 1000')
                // .attr('stroke-dashoffset', -1000)
                .remove();

            // remove old mean label
            d3.select('#svg').selectAll('.mean-label')
                .remove()

            // remove old median label
            d3.select('#svg').selectAll('.med-label')
                .remove()

            // remove old mean line
            d3.select('#svg').selectAll('.mean-line')
                .attr('stroke-dasharray', '1000 1000')
                // .attr('stroke-dashoffset', -1000)
                .remove();

            // remove old median line
            d3.select('#svg').selectAll('.med-line')
                .attr('stroke-dasharray', '1000 1000')
                // .attr('stroke-dashoffset', -1000)
                .remove();

            const meanLine = d3.line()([[xScale(mean), yScale(0) - 2], [xScale(mean), yScale(yMax) + 30]]);
            const medLine = d3.line()([[xScale(median), yScale(0) - 2], [xScale(median), yScale(yMax)]]);

            // add new mean line
            d3.select('#svg')
                .append('path')
                .attr('d', meanLine)
                .attr('class', 'mean-line')

            d3.select('#svg')
                .append("text")
                .attr('class', 'mean-label')
                .attr("x", xScale(mean) + 10)
                .attr("y", yScale(yMax) + 50)
                .text(`Mean`)

            // add new median line
            d3.select('#svg')
                .append('path')
                .attr('d', medLine)
                .attr('class', 'med-line')

            d3.select('#svg')
                .append("text")
                .attr('class', 'med-label')
                .attr("x", xScale(median) + 10)
                .attr("y", yScale(yMax) + 20)
                .text(`Median`)

            //tell props the new max for the city
            props.setMax(xScale.domain()[1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currCity, rainData]
    )

    useEffect(
        () => {
            if (estimate !== undefined && ref.current) {
                // remove old estimate line
                d3.select('#svg').selectAll('.estimate-line')
                    .attr('stroke-dasharray', '1000 1000')
                    // .attr('stroke-dashoffset', -1000)
                    .remove();

                const estimateLine = d3.line()([[xScale(estimate), yScale(0) - 1], [xScale(estimate), yScale(yMax)]]);

                // add new mean line
                d3.select('#svg')
                    .append('path')
                    .attr('d', estimateLine)
                    .attr('class', 'estimate-line')

            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [estimate]
    )

    return (
        <svg width={width} height={height} ref={ref} />
    )
}

function RainChart(props) {
    const { width, height, margin, city, month } = props;
    let { data } = props;

    if (data.length < 2) {
        data.push(0);
    } else {
        data = data.length < 3 ? data : data.slice(0, data.length - 1);
    }

    function getMax() {
        // if not a monsoon month, set max to 3"
        if (!(month in historicalcleaned["2020"][city])) { return 3; }
        let data = [];

        // load data into array to get the max
        for (const year of Object.keys(historicalcleaned)) {
            data.push(historicalcleaned[year][city][month])
        }

        return d3.max(data);
    }

    const maxRain = getMax();

    const ref = useRef(null);
    //  scales
    const xScale = d3.scaleLinear()
        .domain([1, 31])
        .range([margin, width - margin])
    const yScale = d3.scaleLinear()
        .domain([0, maxRain])
        .range([height - margin, margin])

    // axes
    const xAxis = d3.axisBottom(xScale).ticks(5);
    const yAxis = d3.axisLeft(yScale).ticks(5);

    useEffect(
        () => {
            if (ref.current) {
                const svg = d3.select(ref.current)
                    .append('svg')
                    .attr('width', width)
                    .attr('height', height)
                    .append('g')
                    .attr('transform', `translate(10, 0)`)

                let total = 0;
                const line = d3.line()
                    .x((d, i) => xScale(i + 1))
                    .y(d => yScale(total += d) - 1)

                //  create graphs
                svg.append('path')
                    .datum(data)
                    .attr('fill', 'none')
                    .attr('class', 'viz-line')
                    .attr('d', line)

                //  add axes to graph
                svg.append('g')
                    .attr("class", "axisWhite")
                    .attr('id', 'xaxis')
                    .attr('transform', `translate(0, ${height - margin})`)
                    .call(xAxis)
                svg.append('g')
                    .attr("class", "axisWhite")
                    .attr('id', 'yaxis')
                    .attr('transform', `translate(${margin}, 0)`)
                    .call(yAxis)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [width]
    )

    useEffect(() => {
        if (ref.current) {
            const svg = d3.select(ref.current);
            d3.select('#svg').selectAll('path').remove();

            // update y-scale
            yScale.domain([0, getMax()])

            //add new line
            let total = 0;
            const line = d3.line()
                .x((d, i) => xScale(i + 1))
                .y(d => yScale(total += d) - 1)

            // update y-axis
            svg.select('#yaxis')
                .transition().duration(1000)
                .call(d3.axisLeft(yScale).ticks(5))

            //  create graphs
            svg.select('.viz-line')
                .datum(data)
                .transition().duration(1000)
                .attr('d', line)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city])

    return (
        <svg width={width} height={height} ref={ref} />
    )
}

function DigestHistogram(props) {
    let { width, height, displayMonth, selectedYear, city, data, mobile } = props;

    function getData(city) {
        const forecastData = [];
        data.forEach((user) => {
            if (user[displayMonth][city] !== undefined) { // check for forecast
                forecastData.push(user[displayMonth][city].estimate)
            }
        })
        return forecastData;
    }

    // get data into arr for d3
    const forecastData = getData(city);
    city = city === 'El Paso' ? 'El-Paso' : city;

    const ref = useRef(null);
    const margin = { top: 10, right: 25, bottom: 30, left: 25 }

    // bucketing for histogram
    const bin = d3.bin()
    const buckets = bin(forecastData)

    // scales
    const xScale = d3.scaleLinear()
        .domain([0, buckets[buckets.length - 1].x1])
        .range([margin.left, width - margin.right])
    const yScale = d3.scaleLinear()
        .domain([0, d3.max(buckets, d => d.length)])
        .range([height - margin.bottom, margin.top])

    // axes
    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale);

    // initial load of webpage
    useEffect(() => {
        if (ref.current) {
            d3.select(`#svg-${city}`).remove();
            const svg = d3.select(ref.current)
                .attr('width', width + margin.left + margin.right)
                .attr('height', height + margin.top + margin.bottom)
                .append('g')
                .attr('transform', `translate(${margin.left}, ${margin.top})`)
                .attr('id', `svg-${city}`)

            // add the axes
            svg.append('g')
                .attr('class', 'axisWhite')
                .attr('id', 'xaxis')
                .attr('transform', `translate(0, ${height - margin.bottom})`)
                .call(xAxis);
            svg.append('g')
                .attr('class', 'axisWhite')
                .attr('id', 'yaxis')
                .attr('transform', `translate(${margin.left}, 0)`)
                .call(yAxis);

            // labels
            svg.append("text")
                .attr('class', mobile ? 'axisLabel-mobile' : 'axisLabel')
                .attr("x", (mobile ? width - (width / 2) - 75 : 180))
                .attr("y", (mobile ? height + 10 : 370))
                .text(`Forecast (in inches)`)
            svg.append("text")
                .attr('class', mobile ? 'axisLabel-mobile' : 'axisLabel')
                .attr("x", -6)
                .attr("y", height - (height / 2) + (mobile ? 80 : 100))
                .attr("transform", `rotate(270, -6, ${height - (height / 2) + (mobile ? 80 : 100)})`)
                .text(`Number of Forecasts`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]
    )
    // update histogram when new month or year is selected
    useEffect(() => {
        if (ref.current) {
            // update the scales
            xScale.domain([0, buckets[buckets.length - 1].x1]);
            yScale.domain([0, d3.max(buckets, d => d.length)]);

            // update the axes
            d3.select(`#svg-${city}`).select('#xaxis')
                .transition().duration(1000)
                .call(d3.axisBottom(xScale))
            d3.select(`#svg-${city}`).select('#yaxis')
                .transition().duration(1000)
                .call(d3.axisLeft(yScale))

            // remap new data to rects
            const oldBars = d3.select(`#svg-${city}`)
                .selectAll('rect')
                .data(buckets)

            // add new rects and update existing ones
            oldBars.enter().append('rect')
                .attr('y', height - margin.bottom)
                .attr('x', width - margin.right)
                .attr('width', 0)
                .attr('height', 0)
                .merge(oldBars)
                .transition().duration(2000)
                .attr('y', d => yScale(d.length) - 1)
                .attr('x', d => xScale(d.x0) + 2)
                .attr('width', d => xScale(d.x1) - xScale(d.x0))
                .attr('height', d => height - margin.bottom - yScale(d.length))
                .attr('stroke', 'black')
                .attr('stroke-width', 1)
                .attr('fill', '#3AABFF')

            // remove extra rects
            oldBars.exit()
                .transition().duration(2000)
                .attr('height', 0)
                .attr('width', 0)
                .attr('y', height - margin.bottom)
                .attr('x', width - margin.right)
                .remove()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayMonth, selectedYear, data] // update histogram bins whenever month, year, or data is changed
    )

    return (
        <svg width={width} height={height} ref={ref} />
    )
}

export { EstimateHistogram, RainChart, DigestHistogram };

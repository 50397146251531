/**
 * 
 * Author: Thomas Weiss
 * Arizona Institute for Resilience
 */
import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';

export default class Terms extends React.Component {
    constructor(props) {
        super(props);
        document.querySelector("body").scrollTo(0, 0);
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <p>These Terms and Conditions (“Terms”) govern the use of the Monsoon Fantasy Forecasts (“Monsoon Fantasy”). By playing Monsoon Fantasy, you agree to these Terms.</p>
                    </Col>
                    <Col className="col-12 text-white">
                        <ol className="terms-list">
                            <li>
                                1. Eligibility
                                <ol className="terms-list">
                                    <li>1.1. Age: You must be at least 18 years old to participate in the Monsoon Fantasy. By registering, you represent and warrant that you meet this age requirement.</li>
                                </ol>
                            </li>
                            <li>
                                2. Account Registration
                                <ol className="terms-list">
                                    <li>2.1. Account Creation: To play Monsoon Fantasy, you must create an account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials.</li>
                                    <li>2.2. Account Use: You are solely responsible for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</li>
                                </ol>
                            </li>
                            <li>
                                3. Fair Play
                                <ol className="terms-list">
                                    <li>3.1. Registered Accounts: Each user can register for one account. Creating multiple accounts will render you ineligible for winning prizes.</li>
                                </ol>
                            </li>
                            <li>
                                4. Fees
                                <ol className="terms-list">
                                    <li>4.1. Entry Fees: There are no fees.</li>
                                </ol>
                            </li>
                            <li>
                                5. Prizes
                                <ol className="terms-list">
                                    <li>5.1. Prize Distribution: Prizes will be awarded to winners based on the rules and scoring criteria.</li>
                                    <li>5.2. Monthly Prizes: There are 3 monthly prizes for the players who score the most total points in July, August, and September. There is 1 winner per month. The monthly winners receive a monetary amount of $100 each in the form of Amazon gift cards.</li>
                                    <li>5.3. Seasonal Prizes: In addition to the monthly winners, there are three winners for first, second, and third places based on the total score for all months and cities (seasonal winners). Monetary prizes to first, second and third place in the form of Amazon gift cards will be $300, $200, and $100, respectively.</li>
                                    <li>5.4. Winning Monthly and Seasonal prizes: The same player can win monthly and season prizes.</li>
                                    <li>5.5. Timing of Payment: All prize money will be dispersed at the end of the game in October.</li>
                                </ol>
                            </li>
                            <li>
                                6. Taxes
                                <ol className="terms-list">
                                    <li>6.1. W-9: Winners will have to fill out a W-9 form.</li>
                                    <li>6.2. Income: You are responsible for any taxes associated with your winnings.</li>
                                </ol>
                            </li>
                            <li>
                                7. Intellectual Property
                                <ol className="terms-list">
                                    <li>7.1. Ownership: All content from Monsoon Fantasy is the property of the University of Arizona.</li>
                                    <li>7.2. Use of Content: You may use the content provided in the Monsoon Fantasy for personal, non-commercial purposes only. You may not reproduce, distribute, or create derivative works from the content without our express written permission.</li>
                                </ol>
                            </li>
                            <li>
                                8. Privacy
                                <ol className="terms-list">
                                    <li>8.1. Data Collection: We will collect and use the information you fill out voluntarily in your profile and that is associated with the account, including your forecasts and actual point scores. Aggregated, de-identified, forms of the data may be used in future scientific journal publications, like <a href="https://journals.ametsoc.org/view/journals/bams/104/1/BAMS-D-22-0003.1.xml" target="_blank" rel="noopener noreferrer">this article published in the Bulletin of the American Meteorological Society in 2022</a>. If your username is not your real name, the information associated with your account cannot be used to identify you. By using the Monsoon Fantasy, you consent to our data collection and use practices.</li>
                                    <li>8.2. Use of your email: We will periodically send out updates to the email associated with your account during the monsoon season.</li>
                                </ol>
                            </li>
                        </ol>
                    </Col>
                </Row>
            </Container>
        )
    }
}

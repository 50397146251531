/**
 * group.jsx
 */
import React from 'react';
import { Container, Row, Col, Spinner, Modal, Form } from 'react-bootstrap';
import GroupLeaderboards from './groupleaderboards';
import firebase from 'firebase/app';
import { Collapse } from './wrappers';
import AIDetails from './aidetails';

export default class GroupList extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            groups: []
        }
    }
    componentDidMount(){
        this.fetchGroups();
    }

    async fetchGroups() {
        const {firestore} = this.props;
        var grouplist = [];
        await firestore.collection('groups').get().then((docs) => {
            docs.forEach((doc) => {
                grouplist.push({id: doc.id, data: doc.data()})
            });
        });
        grouplist.sort((a, b) => {return a.data.name.localeCompare(b.data.name);});
        this.setState({groups: grouplist})
    }

    render(){
        return (
            <>
            <Container className="card-large mx-auto p-3 pt-4">
                <Row>
                    <Col>
                        <Container>
                            <Row className="text-white lead">
                                <Col className="col-12 col-md-5 d-none d-md-block">Group Name</Col>
                                <Col className="col-12 col-md-3 d-none d-md-block">Members</Col>
                                <Col className="col-12 col-md-4 d-none d-md-block">Leaderboard</Col>
                            </Row>
                            <Row>
                                <Col className="col-12 d-none d-md-block"><hr className="border-secondary mb-2 py-2" /></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col>{this.state.groups.map((group) => (
                        <>
                            <Container className="text-white">
                                <Row>
                                    <Col className="col-12 col-md-5">
                                        <p className="pt-2">{group.data.name} <span className="d-block d-md-none ml-2">({group.data.memberList.length})</span></p>
                                    </Col>
                                    <Col className="col-4 col-md-3 d-none d-md-block">
                                        <p className="pt-2">{group.data.memberList.length}</p>
                                    </Col>
                                    <Col className="col-12 col-md-4 text-md-right">
                                        <button className="btn btn-primary submit-button btn-block" onClick={() => this.props.history.push(`/groups/${group.id}/leaderboard`)}>View Leaderboard</button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr className="border-secondary mb-2 py-2" />
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    ))}</Col>
                </Row>
            </Container>
            </>
        )
    }
}